import { Button, Icon } from "@shopify/polaris";

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  SelectBasic,
  SelectBasicWithSelectTag,
} from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";

import { PrimaryInput } from "../NewCustomComp/Inputs/Inputs";
import {
  PrimaryButton,
  SecondaryButton,
} from "../NewCustomComp/Buttons/Buttons";
import LoaderSmall from "../../UI/Loader/LoaderSmall";
import { SvgCancel } from "../../../assets/svgIcons";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../../assets/toastUtils";
import { getDecryptedData } from "../../../assets/encryptStorage";

const InventoryChannelMapping = ({
  setShow,
  show,
  propertyData,
  selectedChannelMapping,
  properties,
}) => {
  
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loader, setLoader] = useState(false);
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");
  const propertyId = useSelector((state) => state.property.value)?.id;

  const [dataArray, setDataArray] = useState([]);

  const [addMapsData, setAddMapsData] = useState({
    property_id: "",
    property_name: "",
    meal_plan_name: "",
    channel_unique_id: "",
    property_room_id: "",
    channel_room_id: "",
    property_rate_plan_id: "",
    channel_rate_plan_id: "",
    channel_id: selectedChannelMapping?.id,
    channel_name: selectedChannelMapping?.channel_name,
  });

  const setAddItemRateDataHandleChange = (event) => {
    if (event.target.name === "property_rate_plan_id") {
      setAddMapsData({
        ...addMapsData,
        [event.target.name]: event.target.value,
        meal_plan_name: rateOptions.find(
          (item) => +item.value === +event.target.value
        ).label,
      });
      return;
    } else {
      setAddMapsData({
        ...addMapsData,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === "channel_unique_id") {
      setChannelHotelData([]);
      setChannelMealPlans([]);
    }
  };
  const [selectedRoomRates, setSelectedRoomRates] = useState([]);

  const rateOptions = [
    { label: "Select", value: "" }, // Add the default "Select" option
    ...selectedRoomRates?.map((room) => {
      return { label: room.rate_plan_name, value: room.id };
    }),
  ];

  const onRoomChangeFunc = (e) => {
    e.preventDefault();
    const roomId = +e.target.value;

    if (addMapsData.channel_unique_id === "") {
      showErrorToast("Please enter the channel ID");
      return;
    }

    setLoader(true);

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${roomId}/${addMapsData.property_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Check if roomRates exists and is an array
        if (data.success && Array.isArray(data.roomRates)) {
          setSelectedRoomRates(data.roomRates);

          setAddMapsData({
            ...addMapsData,
            property_room_id: roomId,
            room_name: roomOptions.find((room) => +room.value === +roomId)
              ?.label,
          });
        } else if (!data.success) {
          showErrorToast(data.message || "Failed to fetch room rates.");
        } else {
          // Handle unexpected data structure
          showErrorToast("Unexpected data format received.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleAddClick = () => {
    if (addMapsData.property_id === "") {
      showErrorToast("Please select Property ");
      return;
    }
    if (addMapsData.channel_unique_id === "") {
      showErrorToast("Please enter Channel Id ");
      return;
    }

    if (addMapsData.property_room_id === "") {
      showErrorToast("Please select Rooms Types");
      return;
    }

    if (addMapsData.channel_room_id === "") {
      showErrorToast("Please Enter Channel Room Id");
      return;
    }
    if (addMapsData.property_rate_plan_id === "") {
      showErrorToast("please select  Rate Plan Id");
      return;
    }
    if (addMapsData.channel_rate_plan_id === "") {
      showErrorToast("please Enter Channel rate plan Id");
      return;
    }

    setDataArray((prevArray) => [...prevArray, addMapsData]);

    setAddMapsData((prev) => ({
      ...prev,
      property_room_id: "",
      channel_room_id: "",
      property_rate_plan_id: "",
      channel_rate_plan_id: "",
      rate_plan_name: "",
      room_name: "",
    }));
  };

  const handleSubmitAddMeals = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/map/channels`,
        {
          method: "POST",
          body: JSON.stringify({
            data: dataArray,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success === true) {
        showSuccessToast(data.message || "Channel mapped successfully!");
        setShow(false); // Close the modal or hide the form if needed
      } else {
        if (data.message === "Already Mapped") {
          showErrorToast("This channel has already been mapped.");
          setShow(false);
        } else {
          throw new Error(data.message || "Something went wrong!");
        }
      }
    } catch (error) {
      showErrorToast(error.message || "An error occurred during the request.");
    }
  };

  // ============= channel hotel data =======================

  const handleInputBlur = () => {
    if (
      addMapsData.channel_unique_id !== "" ||
      addMapsData.channel_unique_id !== null
    ) {
      fetchChannelHotelData(addMapsData.channel_unique_id);
    }
  };
  const [channelHotelData, setChannelHotelData] = useState([]);

  const channelHotelDataOptions = [
    { label: "Select", value: "" }, // Add the default "Select" option
    ...channelHotelData?.map((room) => {
      return { label: room.RoomName, value: room.RoomCode };
    }),
  ];


  console.log(channelHotelData, "channelHotelData");

  const [channelMealPlans, setChannelMealPlans] = useState([]);

  const channelMealPlansOptions = [
    { label: "Select", value: "" }, // Add the default "Select" option
    ...channelMealPlans?.map((room) => {
      return { label: room?.RatePlanName, value: room?.RatePlanCode };
    }),
  ];

  const fetchChannelHotelData = async (channel_hotel_id) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/fetch/hotel/details/${addMapsData?.property_id}?channel_id=${addMapsData.channel_id}&channel_hotel_id=${channel_hotel_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        setChannelHotelData(data.rooms ? data.rooms : []);
        showSuccessToast("Hotel Data Fetched Successfully");
      } else {
        showInfoToast(
          "Could not find Hotel Data. Please Enter chanel room id and channel meal plan id manually"
        );
        setChannelHotelData(data.rooms ? data.rooms : []);
      }
    } catch (error) {
      showInfoToast(
        "Could not find Hotel Data. Please Enter chanel room id and channel meal plan id manually"
      );
    } finally {
      // setLoader(false);
    }
  };

  const handleKeyDown = (e) => {
    const value = e.target.value;
    if (e.keyCode === 13) {
      fetchChannelHotelData(value);
      setAddMapsData((prev) => ({
        ...prev,
        channel_unique_id: value,
      }));
    }
  };

  const handleChannelRoomChange = (e) => {
    const data = e?.target?.value;
    if (data) {
      setAddMapsData((prev) => ({
        ...prev,
        channel_room_id: data,
      }));

      const selectedRoom = channelHotelData?.find(
        (item) => item?.RoomCode?.toString() === data?.toString()
      )?.ratePlans;
      setChannelMealPlans(selectedRoom);
    }
  };

  const handleChannelRatePlanChange = (e) => {
    const data = e?.target?.value;
    setAddMapsData((prev) => ({
      ...prev,
      channel_rate_plan_id: data,
    }));
  };

  const listProperties = [
    { label: "Select", value: "" },

    ...properties?.map((item) => ({
      label: item?.property_name,
      value: item?.id,
    })),
  ];
  
  const handlePropertyChange = (event) => {
    const selectedPropertyId = event.target.value;

    const selectedProperty = properties.find(
      (item) => String(item.value) === String(selectedPropertyId)
    );

    setAddMapsData((prev) => ({
      ...prev,
      property_id: selectedPropertyId,
      property_name: selectedProperty?.label || "", // Use label here as well
    }));
  };

  const [categories, setCategories] = useState([]);

  const fetchRooms = async (property_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json().then((data) => {
        if (data.success) {
          setCategories(data.rooms);

          setLoader(false);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (addMapsData?.property_id) {
      fetchRooms(addMapsData?.property_id);
    }
  }, [addMapsData?.property_id]);

  const roomOptions = [
    { label: "Select", value: "" }, // Add the default "Select" option
    ...categories?.map((room) => {
      return { label: room.room_name, value: room.id };
    }),
  ];

  return (
    <>
      <div className="bg-backdrop">
        <div className="stock-add-model-container" style={{ width: "850px" }}>
          <div
            className="flex justify_content_between align_items_center"
            style={{ marginBottom: "15px" }}
          >
            <div className="add-ota-model-container-heading align_items_center">
              Mapping
            </div>
            <div
              onClick={() => setShow(false)}
              style={{ cursor: "pointer", fontSize: "20px", color: "#8d8989" }}
            >
              <SvgCancel color={"#8d8989"} height={"20px"} width={"20px"} />
            </div>
          </div>

          <div className="form-container-div-half">
            <div className="form-container-div">
              <SelectBasicWithSelectTag
                onChange={handlePropertyChange}
                label={"Properties"}
                size={"medium"}
                options={listProperties}
                disabled={dataArray.length > 0 ? true : false}
              />
            </div>
            <div className="form-container-div">
              <PrimaryInput
                onFocus={() => [
                  setChannelHotelData([]),
                  setChannelMealPlans([]),
                ]}
                label="Hotel ID"
                name="channel_unique_id"
                type="number"
                value={addMapsData.channel_unique_id}
                onChange={setAddItemRateDataHandleChange}
                disabled={dataArray.length > 0 ? true : false}
                onKeyDown={handleKeyDown}
                onBlur={handleInputBlur}
                size={"medium"}
              />
            </div>
          </div>

          {loader ? (
            <LoaderSmall />
          ) : (
            <>
              {roomOptions && roomOptions.length > 0 && (
                <div className="flex flex_gap_10">
                  <SelectBasicWithSelectTag
                    label={"Rooms"}
                    name="property_room_id"
                    options={roomOptions}
                    value={addMapsData.property_room_id}
                    onChange={(e) => onRoomChangeFunc(e)}
                    size={"medium"}
                    style={{ width: "200px" }}
                  />

                  {channelHotelData && channelHotelData.length > 0 ? (
                    <SelectBasicWithSelectTag
                      label={"Channel Room"}
                      name="channel_room_id"
                      options={channelHotelDataOptions}
                      value={addMapsData.channel_room_id}
                      onChange={(e) => handleChannelRoomChange(e)}
                      size={"medium"}
                      style={{ width: "150px" }}
                    />
                  ) : (
                    <PrimaryInput
                      label="Channel Room Id"
                      name="channel_room_id"
                      type="number"
                      value={addMapsData.channel_room_id}
                      onChange={setAddItemRateDataHandleChange}
                      size={"medium"}
                      style={{ width: "150px", marginTop: "4px" }}
                    />
                  )}

                  {rateOptions && rateOptions.length > 0 && (
                    <div className="flex flex_gap_10 align_items_end">
                      <SelectBasicWithSelectTag
                        label={"Room Rate"}
                        options={rateOptions}
                        name={"property_rate_plan_id"}
                        value={addMapsData.property_rate_plan_id}
                        onChange={setAddItemRateDataHandleChange}
                        size={"medium"}
                        style={{ width: "200px" }}
                      />

                      {channelMealPlans && channelMealPlans.length > 0 ? (
                        <SelectBasicWithSelectTag
                          options={channelMealPlansOptions}
                          label="Channel RatePlan"
                          name="channel_rate_plan_id"
                          value={addMapsData.channel_rate_plan_id}
                          onChange={handleChannelRatePlanChange}
                          size={"medium"}
                          style={{ width: "200px" }}
                        />
                      ) : (
                        <PrimaryInput
                          label="Channel Rate Id"
                          name="channel_rate_plan_id"
                          type="number"
                          value={addMapsData.channel_rate_plan_id}
                          onChange={setAddItemRateDataHandleChange}
                          size={"medium"}
                          style={{ width: "150px" }}
                        />
                      )}

                      <button
                        onClick={handleAddClick}
                        style={{
                          cursor: "pointer",
                          background: "transparent",
                          border: "1px solid #3968ed",
                          borderRadius: "2px",
                          padding: "2px",
                          display: "grid",
                          placeItems: "center",
                          width: "fit-content",
                          height: "fit-content",
                        }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 0H9V9H0V11H9V20H11V11H20V9H11V0Z"
                            fill="#3968ed"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {dataArray.length > 0 ? (
            <>
              <div style={{ margin: "10px  0" }}>
                {dataArray.map((item, index) => {
                  return (
                    <div
                      className="flex flex_gap_10 align_items_end"
                      key={index}
                      style={{ paddingBottom: "10px" }}
                    >
                      <PrimaryInput
                        value={item.room_name}
                        disabled={true}
                        size={"medium"}
                        style={{ width: "200px" }}
                      />
                      <PrimaryInput
                        value={item.channel_room_id}
                        disabled={true}
                        size={"medium"}
                        style={{ width: "150px" }}
                      />

                      <PrimaryInput
                        value={item.meal_plan_name}
                        disabled={true}
                        size={"medium"}
                        style={{ width: "200px" }}
                      />
                      <PrimaryInput
                        value={item.channel_rate_plan_id}
                        disabled={true}
                        size={"medium"}
                        style={{ width: "150px" }}
                      />

                      <div>
                        <button
                          onClick={() =>
                            setDataArray(
                              dataArray.filter(
                                (_, dataIndex) => dataIndex !== index
                              )
                            )
                          }
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.2001 3.2H12.6001C13.0419 3.2 13.4001 3.55817 13.4001 4L13.4001 4.8H0.600098L0.600119 4C0.600119 3.55817 0.958291 3.2 1.40012 3.2H3.80012V1.2C3.80012 0.537258 4.33738 0 5.00012 0H9.00012C9.66286 0 10.2001 0.537259 10.2001 1.2V3.2ZM5.40012 1.6V3.2H8.60012V1.6H5.40012Z"
                              fill="#E03838"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.40012 6.4H12.6001V14.8C12.6001 15.4627 12.0629 16 11.4001 16H2.60012C1.93738 16 1.40012 15.4627 1.40012 14.8V6.4ZM4.6001 8.8H3.0001V13.6H4.6001V8.8ZM7.8001 8.8H6.2001V13.6H7.8001V8.8ZM9.4001 8.8H11.0001V13.6H9.4001V8.8Z"
                              fill="#E03838"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}

          <div className="flex flex_gap_10" style={{ margin: "15px 0" }}>
            <PrimaryButton
              onClick={(e) => handleSubmitAddMeals(e)}
              title={"Add"}
              disabled={dataArray.length > 0 ? false : true}
              size={"medium"}
            />
            <SecondaryButton
              onClick={() => setShow(false)}
              title={"Cancel"}
              size={"medium"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryChannelMapping;

// import { useState, useCallback, useEffect } from "react";

// import Loader from "../UI/Loader/Loader";
// import "./rateCalender.css";
// import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
// import { useNavigate, useParams } from "react-router-dom";
// import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
// import { BgThemeButton } from "../UI/Buttons";
// import {
//   PrimaryButton,
//   SecondaryButton,
// } from "../CustomComponents/NewCustomComp/Buttons/Buttons";
// import RatesImport from "./RatesImport";
// import GoBackButtonCustom from "../CustomComponents/GoBackButtonCustom/GoBackButtonCustom";
// import { getDecryptedData } from "../../assets/encryptStorage";

// const RateCalender = () => {
  
//   const userToken = getDecryptedData("encryptToken");

//   const [loader, setLoader] = useState(true);
//   const [orders, setOrders] = useState([]);

//   const { compId } = useParams();

//   const fetchInfo = async () => {
//     // console.log(id, "propertyId");
//     try {
//       setLoader(true);
//       const response = await fetch(
//         //{{development}}/api/v1/admin/channels/list
//         `${process.env.REACT_APP_BASE_URL}/api/v1/admin/get-competitor-rate/${compId}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//         }
//       );
//       const data = await response.json().then((data) => {
//         if (data.success) {
//           setDatePriceArray(data.competitorsRates);
//         }
//       });
//       setLoader(false);
//     } catch (error) {
//       console.error(error);
//       setLoader(false);
//     }
//   };

//   useEffect(() => {
//     fetchInfo();
//   }, []);

//   setTimeout(() => {
//     setLoader(false);
//   }, 2000);

//   //
//   const dataPriceArray = [
//     { rate_date: "2023-11-01", rate: "12" },
//     { rate_date: "2023-11-01", rate: "12" },
//     { rate_date: "2023-11-03", rate: "345" },
//     { rate_date: "2023-11-06", rate: "9" },
//     { rate_date: "2023-11-07", rate: "09" },
//     { rate_date: "2023-11-13", rate: "122" },
//     { rate_date: "2023-11-14", rate: "82" },
//     { rate_date: "2023-11-17", rate: "78" },
//     { rate_date: "2023-11-18", rate: "80" },
//     { rate_date: "2023-11-20", rate: "90" },
//     { rate_date: "2023-11-30", rate: "01" },
//     { rate_date: "2023-11-28", rate: "32" },
//     { rate_date: "2023-11-26", rate: "90" },
//     { rate_date: "2023-11-25", rate: "45" },
//     { rate_date: "2023-11-24", rate: "12" },
//     { rate_date: "2023-11-23", rate: "45" },
//     { rate_date: "2023-11-22", rate: "51" },
//     { rate_date: "2023-12-02", rate: "12" },
//     { rate_date: "2023-12-03", rate: "45" },
//     { rate_date: "2023-12-10", rate: "51" },
//     { rate_date: "2024-01-10", rate: "51" },
//   ];

//   const dateCheck = [
//     { rate_date: "2023-10-25", rate: "1000" },
//     { rate_date: "2023-11-03", rate: "4000" },
//   ];

//   const [currentMonth, setCurrentMonth] = useState(new Date());
//   const [nextMonth, setNextMonth] = useState(currentMonth + 1);

//   const [datePriceArray, setDatePriceArray] = useState([]);

//   const handleInputChange = (e, day) => {
//     const inputValue = e.target.value.trim();
//     const isDelete = e.nativeEvent.inputType === "deleteContentBackward";

//     // Proceed only if the input is not empty or it's a deletion action
//     if (inputValue !== "" || isDelete) {
//       const inputDate = new Date(
//         currentMonth.getFullYear(),
//         currentMonth.getMonth(),
//         day
//       );
//       const year = inputDate.getFullYear();
//       const month = String(inputDate.getMonth() + 1).padStart(2, "0");
//       const formattedDay = String(inputDate.getDate()).padStart(2, "0");

//       const formattedDate = `${year}-${month}-${formattedDay}`;
//       const updatedArray = [...datePriceArray];

//       const existingIndex = updatedArray.findIndex(
//         (item) => item.rate_date === formattedDate
//       );

//       if (isDelete) {
//         // If it's a deletion action, remove the last character from the rate value
//         if (existingIndex !== -1) {
//           updatedArray[existingIndex].rate = e.target.value;
//         }
//       } else {
//         if (existingIndex !== -1) {
//           // If the date entry already exists, update the rate value
//           updatedArray[existingIndex].rate = e.target.value;
//         } else {
//           // If it's a new entry, add it to the array
//           updatedArray.push({
//             rate_date: formattedDate,
//             rate: e.target.value,
//           });
//         }
//       }

//       // Update the state with the updated array
//       setDatePriceArray(updatedArray);
//     }
//   };

//   const handleNextMonth = () => {
//     const nextMonthDate = new Date(
//       currentMonth.getFullYear(),
//       currentMonth.getMonth() + 1
//     );
//     setCurrentMonth(nextMonthDate);
//   };

//   const handlePreviousMonth = () => {
//     const previousMonthDate = new Date(
//       currentMonth.getFullYear(),
//       currentMonth.getMonth() - 1
//     );
//     setCurrentMonth(previousMonthDate);
//   };

//   const getDaysInMonth = (date) => {
//     const year = date.getFullYear();
//     const month = date.getMonth();
//     return new Date(year, month + 1, 0).getDate();
//   };

//   const getFirstDayOfMonth = (date) => {
//     const year = date.getFullYear();
//     const month = date.getMonth();
//     return new Date(year, month, 1).getDay();
//   };

//   const daysInMonth = getDaysInMonth(currentMonth);
//   const firstDayOfMonth = getFirstDayOfMonth(currentMonth);

//   const days = [];
//   for (let i = 1; i <= daysInMonth; i++) {
//     days.push(i);
//   }

//   const emptyDays = [];
//   for (let i = 0; i < firstDayOfMonth; i++) {
//     emptyDays.push(i);
//   }

//   const handleSubmitAddMeals = (event) => {
//     event.preventDefault();
//     setLoader(true);
//     fetch(
//       `${process.env.REACT_APP_BASE_URL}/api/v1/admin/update/bulk-competitor-rate/${compId}`,
//       {
//         method: "POST",
//         body: JSON.stringify({
//           updatedRates: datePriceArray,
//         }),
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${userToken}`,
//         },
//       }
//     )
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           setLoader(false);
//           showSuccessToast(data.message);
//           fetchInfo();
//         } else {
//           showErrorToast(data.message);
//           setLoader(false);
//         }
//       })
//       .catch((error) => {
//         showErrorToast("Something went wrong");
//       });
//   };

//   const [bulkUpdate, setBulkUpdate] = useState(false);

//   const handleShowPopup = () => {
//     setBulkUpdate(!bulkUpdate);
//   };

//   const [focusedDay, setFocusedDay] = useState(null);

//   const handleFocus = (day) => {
//     setFocusedDay(day);
//   };

//   const handleBlur = () => {
//     // Delay blur to allow click on the "Save" button
//     setTimeout(() => setFocusedDay(null), 1000);
//   };
//    const navigate = useNavigate();
//   return (
//     <>
//       {loader ? (
//         <Loader />
//       ) : (
//         <>
//           <div className="room-types-full-container">
//             <div className="room-types-full-container-div">
//               <div className="room-type-heading flex flex_gap_10">
//                 <GoBackButtonCustom onClick={()=>navigate(-1)}/>
//                 Rate Calender
                
                
//                 </div>
//               <div style={{ display: "flex", gap: "10px" }}>
//                 <SecondaryButton
//                   onClick={handleShowPopup}
//                   title={"Import Rates"}
//                   size={"medium"}
//                 />
//                 <PrimaryButton
//                   onClick={handleSubmitAddMeals}
//                   title={"Update Rates"}
//                   size={"medium"}
//                 />
//               </div>
//             </div>

//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 gap: "10px",
//                 width: "100%",
//               }}
//             >
//               <div className="calendar">
//                 <div className="month-header">
//                   <button
//                     onClick={handlePreviousMonth}
//                     className="customFullWidthCalenderBtn"
//                   >
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="40"
//                       height="40"
//                       viewBox="0 0 30 30"
//                       fill="none"
//                     >
//                       <rect
//                         x="29.75"
//                         y="29.75"
//                         width="29.5"
//                         height="29.5"
//                         rx="5.75"
//                         transform="rotate(180 29.75 29.75)"
//                         stroke="#666666"
//                         strokeWidth="0.5"
//                       />
//                       <line
//                         x1="21"
//                         y1="15.043"
//                         x2="9"
//                         y2="15.043"
//                         stroke="#666666"
//                       />
//                       <path
//                         d="M13.6284 19.2572L8.99985 14.6286L13.6284 10.0001"
//                         stroke="#666666"
//                         strokeWidth="1.2"
//                       />
//                     </svg>
//                   </button>
//                   <h3>
//                     {currentMonth.toLocaleString("default", { month: "long" })}{" "}
//                     (
//                     {currentMonth.toLocaleString("default", {
//                       year: "numeric",
//                     })}
//                     )
//                   </h3>
//                   <button
//                     onClick={handleNextMonth}
//                     className="customFullWidthCalenderBtn"
//                   >
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       width="40"
//                       height="40"
//                       viewBox="0 0 30 30"
//                       fill="none"
//                     >
//                       <rect
//                         x="0.25"
//                         y="0.25"
//                         width="29.5"
//                         height="29.5"
//                         rx="5.75"
//                         stroke="#666666"
//                         strokeWidth="0.5"
//                       />
//                       <line
//                         x1="9"
//                         y1="14.957"
//                         x2="21"
//                         y2="14.957"
//                         stroke="#666666"
//                       />
//                       <path
//                         d="M16.3716 10.7428L21.0002 15.3714L16.3716 19.9999"
//                         stroke="#666666"
//                         strokeWidth="1.2"
//                       />
//                     </svg>
//                   </button>
//                 </div>
//                 <div className="day-headers">
//                   <div>Sun</div>
//                   <div>Mon</div>
//                   <div>Tue</div>
//                   <div>Wed</div>
//                   <div>Thu</div>
//                   <div>Fri</div>
//                   <div>Sat</div>
//                 </div>
//                 <div className="days">
//                   {emptyDays.map((_, index) => (
//                     <div key={`empty-${index}`} className="empty-day"></div>
//                   ))}

//                   {days.map((day) => (
//                     <div
//                       key={day}
//                       className="day"
//                       onFocus={() => setFocusedDay(day)}
//                       onBlur={(e) => {
                        
//                         if (!e.currentTarget.contains(e.relatedTarget)) {
//                           setFocusedDay(null);
//                         }
//                       }}
//                       tabIndex={-1} // Focusable only via child inputs
//                     >
//                       {day}
//                       <div style={{ width: "50%" }}>
//                         <VerticalInputFieldCustom
//                           type="number"
//                           style={{
//                             width: "100%",
//                             height: "3px",
//                             textAlign: "center",
//                           }}
//                           className="custom-input"
//                           onChange={(e) => handleInputChange(e, day)}
//                           value={
//                             datePriceArray.find(
//                               (item) =>
//                                 item.rate_date ===
//                                 `${currentMonth.getFullYear()}-${String(
//                                   currentMonth.getMonth() + 1
//                                 ).padStart(2, "0")}-${String(day).padStart(
//                                   2,
//                                   "0"
//                                 )}`
//                             )?.rate || ""
//                           }
//                         />
//                       </div>
                      
                      
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>

//           {bulkUpdate && (
//             <RatesImport
//               title={"Bulk Update"}
//               setShowAddModelMenuActive={setBulkUpdate}
//               showAddModelMenuActive={bulkUpdate}
//               id={compId}
//             />
//           )}
//         </>
//       )}
//     </>
//   );
// };
// export default RateCalender;


import { useState, useCallback, useEffect } from "react";

import Loader from "../UI/Loader/Loader";
import "./rateCalender.css";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { BgThemeButton } from "../UI/Buttons";
import { getDecryptedData } from "../../assets/encryptStorage";
import {
  PrimaryButton,
  SecondaryButton,
} from "../CustomComponents/NewCustomComp/Buttons/Buttons";
import RatesImport from "./RatesImport";
import GoBackButtonCustom from "../CustomComponents/GoBackButtonCustom/GoBackButtonCustom";

const RateCalender = () => {
  
  const userToken = getDecryptedData("encryptToken");

  const [loader, setLoader] = useState(true);
  const [orders, setOrders] = useState([]);

  const { compId } = useParams();

  const fetchInfo = async () => {
    // console.log(id, "propertyId");
    try {
      setLoader(true);
      const response = await fetch(
        //{{development}}/api/v1/admin/channels/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/get-competitor-rate/${compId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setDatePriceArray(data.competitorsRates);
        }
      });
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 2000);

  //
  const dataPriceArray = [
    { rate_date: "2023-11-01", rate: "12" },
    { rate_date: "2023-11-01", rate: "12" },
    { rate_date: "2023-11-03", rate: "345" },
    { rate_date: "2023-11-06", rate: "9" },
    { rate_date: "2023-11-07", rate: "09" },
    { rate_date: "2023-11-13", rate: "122" },
    { rate_date: "2023-11-14", rate: "82" },
    { rate_date: "2023-11-17", rate: "78" },
    { rate_date: "2023-11-18", rate: "80" },
    { rate_date: "2023-11-20", rate: "90" },
    { rate_date: "2023-11-30", rate: "01" },
    { rate_date: "2023-11-28", rate: "32" },
    { rate_date: "2023-11-26", rate: "90" },
    { rate_date: "2023-11-25", rate: "45" },
    { rate_date: "2023-11-24", rate: "12" },
    { rate_date: "2023-11-23", rate: "45" },
    { rate_date: "2023-11-22", rate: "51" },
    { rate_date: "2023-12-02", rate: "12" },
    { rate_date: "2023-12-03", rate: "45" },
    { rate_date: "2023-12-10", rate: "51" },
    { rate_date: "2024-01-10", rate: "51" },
  ];

  const dateCheck = [
    { rate_date: "2023-10-25", rate: "1000" },
    { rate_date: "2023-11-03", rate: "4000" },
  ];

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [nextMonth, setNextMonth] = useState(currentMonth + 1);

  const [datePriceArray, setDatePriceArray] = useState([]);

  const handleInputChange = (e, day) => {
    const inputValue = e.target.value.trim();
    const isDelete = e.nativeEvent.inputType === "deleteContentBackward";

    // Proceed only if the input is not empty or it's a deletion action
    if (inputValue !== "" || isDelete) {
      const inputDate = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        day
      );
      const year = inputDate.getFullYear();
      const month = String(inputDate.getMonth() + 1).padStart(2, "0");
      const formattedDay = String(inputDate.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${formattedDay}`;
      const updatedArray = [...datePriceArray];

      const existingIndex = updatedArray.findIndex(
        (item) => item.rate_date === formattedDate
      );

      if (isDelete) {
        // If it's a deletion action, remove the last character from the rate value
        if (existingIndex !== -1) {
          updatedArray[existingIndex].rate = e.target.value;
        }
      } else {
        if (existingIndex !== -1) {
          // If the date entry already exists, update the rate value
          updatedArray[existingIndex].rate = e.target.value;
        } else {
          // If it's a new entry, add it to the array
          updatedArray.push({
            rate_date: formattedDate,
            rate: e.target.value,
          });
        }
      }

      // Update the state with the updated array
      setDatePriceArray(updatedArray);
    }
  };

  const handleNextMonth = () => {
    const nextMonthDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1
    );
    setCurrentMonth(nextMonthDate);
  };

  const handlePreviousMonth = () => {
    const previousMonthDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1
    );
    setCurrentMonth(previousMonthDate);
  };

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month, 1).getDay();
  };

  const daysInMonth = getDaysInMonth(currentMonth);
  const firstDayOfMonth = getFirstDayOfMonth(currentMonth);

  const days = [];
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  const emptyDays = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    emptyDays.push(i);
  }

  const handleSubmitAddMeals = (event) => {
    event.preventDefault();
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/update/bulk-competitor-rate/${compId}`,
      {
        method: "POST",
        body: JSON.stringify({
          updatedRates: datePriceArray,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setLoader(false);
          showSuccessToast(data.message);
          fetchInfo();
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        showErrorToast("Something went wrong");
      });
  };

  const [bulkUpdate, setBulkUpdate] = useState(false);

  const handleShowPopup = () => {
    setBulkUpdate(!bulkUpdate);
  };

  const [focusedDay, setFocusedDay] = useState(null);

  const handleFocus = (day) => {
    setFocusedDay(day);
  };

  const handleBlur = () => {
    // Delay blur to allow click on the "Save" button
    setTimeout(() => setFocusedDay(null), 1000);
  };
   const navigate = useNavigate();
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading flex flex_gap_10">
                <GoBackButtonCustom onClick={()=>navigate(-1)}/>
                Rate Calender
                
                
                </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <SecondaryButton
                  onClick={handleShowPopup}
                  title={"Import Rates"}
                  size={"medium"}
                />
                <PrimaryButton
                  onClick={handleSubmitAddMeals}
                  title={"Update Rates"}
                  size={"medium"}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              <div className="calendar">
                <div className="month-header">
                  <button
                    onClick={handlePreviousMonth}
                    className="customFullWidthCalenderBtn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <rect
                        x="29.75"
                        y="29.75"
                        width="29.5"
                        height="29.5"
                        rx="5.75"
                        transform="rotate(180 29.75 29.75)"
                        stroke="#666666"
                        strokeWidth="0.5"
                      />
                      <line
                        x1="21"
                        y1="15.043"
                        x2="9"
                        y2="15.043"
                        stroke="#666666"
                      />
                      <path
                        d="M13.6284 19.2572L8.99985 14.6286L13.6284 10.0001"
                        stroke="#666666"
                        strokeWidth="1.2"
                      />
                    </svg>
                  </button>
                  <h3>
                    {currentMonth.toLocaleString("default", { month: "long" })}{" "}
                    (
                    {currentMonth.toLocaleString("default", {
                      year: "numeric",
                    })}
                    )
                  </h3>
                  <button
                    onClick={handleNextMonth}
                    className="customFullWidthCalenderBtn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <rect
                        x="0.25"
                        y="0.25"
                        width="29.5"
                        height="29.5"
                        rx="5.75"
                        stroke="#666666"
                        strokeWidth="0.5"
                      />
                      <line
                        x1="9"
                        y1="14.957"
                        x2="21"
                        y2="14.957"
                        stroke="#666666"
                      />
                      <path
                        d="M16.3716 10.7428L21.0002 15.3714L16.3716 19.9999"
                        stroke="#666666"
                        strokeWidth="1.2"
                      />
                    </svg>
                  </button>
                </div>
                <div className="day-headers">
                  <div>Sun</div>
                  <div>Mon</div>
                  <div>Tue</div>
                  <div>Wed</div>
                  <div>Thu</div>
                  <div>Fri</div>
                  <div>Sat</div>
                </div>
                <div className="days">
                  {emptyDays.map((_, index) => (
                    <div key={`empty-${index}`} className="empty-day"></div>
                  ))}

                  {days.map((day) => (
                    <div
                      key={day}
                      className="day"
                      onFocus={() => setFocusedDay(day)}
                      onBlur={(e) => {
                        
                        if (!e.currentTarget.contains(e.relatedTarget)) {
                          setFocusedDay(null);
                        }
                      }}
                      tabIndex={-1} // Focusable only via child inputs
                    >
                      {day}
                      <div style={{ width: "50%" }}>
                        <VerticalInputFieldCustom
                          type="number"
                          style={{
                            width: "100%",
                            height: "3px",
                            textAlign: "center",
                          }}
                          className="custom-input"
                          onChange={(e) => handleInputChange(e, day)}
                          value={
                            datePriceArray.find(
                              (item) =>
                                item.rate_date ===
                                `${currentMonth.getFullYear()}-${String(
                                  currentMonth.getMonth() + 1
                                ).padStart(2, "0")}-${String(day).padStart(
                                  2,
                                  "0"
                                )}`
                            )?.rate || ""
                          }
                        />
                      </div>
                      
                      
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {bulkUpdate && (
            <RatesImport
              title={"Bulk Update"}
              setShowAddModelMenuActive={setBulkUpdate}
              showAddModelMenuActive={bulkUpdate}
              id={compId}
            />
          )}
        </>
      )}
    </>
  );
};
export default RateCalender;


import React, { useEffect, useRef, useState } from 'react';
import styles from "./PropertyTargets.module.scss";
import NavTabs from '../NewCustomComp/NavTabs/NavTabs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { CurtainTable } from '../NewCustomComp/Curtain/Curtain';
import { useParams } from 'react-router-dom';
import { SvgBack, SvgDelete, SvgEdit, SvgSettings } from '../../svgIcons/Icons';
import { PrimaryInput } from '../NewCustomComp/Inputs/Inputs';
import { PrimaryButton } from '../NewCustomComp/Buttons/Buttons';
import { showSuccessToast } from '../../../assets/toastUtils';
import { getDateAndMonth } from '../../../assets/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const PropertyTargets = () => {

    const pathname = useParams()
    const { propertyId } = pathname




    // Arrays
    // const switchTabs = [
    //     { label: "Property Targets", to: "/property-targets" },
    //     { label: "Competitor Rates", to: "/property-targets/competitors-targets" }
    // ]
    const tableHeading = [
        { heading: "Months" },
        { heading: "Occupancy" },
        { heading: "ADR" },
        { heading: "RevPAR" },
        { heading: "Revenue" },
    ]
    const tableHeadingSlider = [
        { heading: "Days" },
        { heading: "Occupancy" },
        { heading: "ADR" },
        { heading: "RevPAR" },
        { heading: "Revenue" },
    ]
    const tableContent = [
        { month: "December", occTrg: 40, occCurr: 80, adrTrg: 5000, adrCurr: 10000, parTrg: 5000000, parCurr: 100000, revTrg: 500000, revCurr: 100000 },
        { month: "November", occTrg: 70, occCurr: 50, adrTrg: 90000, adrCurr: 10000, parTrg: 500000, parCurr: 1000000, revTrg: 500000, revCurr: 100000 },
        { month: "October", occTrg: 40, occCurr: 80, adrTrg: 5000, adrCurr: 10000, parTrg: 5000000, parCurr: 1000000, revTrg: 500000, revCurr: 1000000 },
        { month: "September", occTrg: 70, occCurr: 20, adrTrg: 90000, adrCurr: 1000, parTrg: 500000, parCurr: 1000000, revTrg: 500000, revCurr: 1000000 },
        { month: "August", occTrg: 40, occCurr: 80, adrTrg: 5000, adrCurr: 10000, parTrg: 5000000, parCurr: 100000, revTrg: 500000, revCurr: 100000 },
        { month: "July", occTrg: 90, occCurr: 80, adrTrg: 90000, adrCurr: 10000, parTrg: 500000, parCurr: 100000, revTrg: 500000, revCurr: 1000000 },
        { month: "June", occTrg: 40, occCurr: 80, adrTrg: 5000, adrCurr: 1000, parTrg: 5000000, parCurr: 1000000, revTrg: 500000, revCurr: 100000 },
        { month: "May", occTrg: 90, occCurr: 80, adrTrg: 90000, adrCurr: 10000, parTrg: 500000, parCurr: 100000, revTrg: 500000, revCurr: 1000000 },
        { month: "April", occTrg: 40, occCurr: 80, adrTrg: 5000, adrCurr: 1000, parTrg: 5000000, parCurr: 1000000, revTrg: 500000, revCurr: 1000000 },
        { month: "March", occTrg: 70, occCurr: 80, adrTrg: 90000, adrCurr: 10000, parTrg: 500000, parCurr: 100000, revTrg: 500000, revCurr: 1000000 },
        { month: "February", occTrg: 90, occCurr: 80, adrTrg: 5000, adrCurr: 1000, parTrg: 5000000, parCurr: 1000000, revTrg: 500000, revCurr: 100000 },
        { month: "January", occTrg: 70, occCurr: 80, adrTrg: 90000, adrCurr: 10000, parTrg: 500000, parCurr: 100000, revTrg: 500000, revCurr: 100000 },
    ]
    // const graphData = [
    //     {
    //         title: "Occupancy",
    //         percentage: "70%",
    //         color: "#3968ED",
    //         GraphCurr: [tableContent[0].occCurr, tableContent[1].occCurr, tableContent[2].occCurr, tableContent[3].occCurr, tableContent[4].occCurr, tableContent[5].occCurr, tableContent[6].occCurr, tableContent[7].occCurr, tableContent[8].occCurr, tableContent[9].occCurr, tableContent[10].occCurr, tableContent[11].occCurr],
    //         // GraphTrg: [500, 200, 400]
    //     },
    //     {
    //         title: "ADR",
    //         percentage: "5,000",
    //         color: "#FF385C",
    //         GraphCurr: [tableContent[0].adrCurr, tableContent[1].adrCurr, tableContent[2].adrCurr, tableContent[3].adrCurr, tableContent[4].adrCurr, tableContent[5].adrCurr, tableContent[6].adrCurr, tableContent[7].adrCurr, tableContent[8].adrCurr, tableContent[9].adrCurr, tableContent[10].adrCurr, tableContent[11].adrCurr],
    //         // GraphTrg: [500, 200, 400]
    //     },
    //     {
    //         title: "RevPAR",
    //         percentage: "5,00,000",
    //         color: "#FA8010",
    //         GraphCurr: [tableContent[0].parCurr, tableContent[1].parCurr, tableContent[2].parCurr, tableContent[3].parCurr, tableContent[4].parCurr, tableContent[5].parCurr, tableContent[6].parCurr, tableContent[7].parCurr, tableContent[8].parCurr, tableContent[9].parCurr, tableContent[10].parCurr, tableContent[11].parCurr],
    //         // GraphTrg: [500, 200, 400]
    //     },
    //     {
    //         title: "Revenue",
    //         percentage: "5,00,000",
    //         color: "#15AA12",
    //         GraphCurr: [tableContent[0].revCurr, tableContent[1].revCurr, tableContent[2].revCurr, tableContent[3].revCurr, tableContent[4].revCurr, tableContent[5].revCurr, tableContent[6].revCurr, tableContent[7].revCurr, tableContent[8].revCurr, tableContent[9].revCurr, tableContent[10].revCurr, tableContent[11].revCurr],
    //         // GraphTrg: [5000, 2000, 4000]
    //     },
    // ]


    // Array End


    // Curtain Function
    const sliderRef = useRef(null)
    const [show, setShow] = useState("");
    const [activeMonth, setActiveMonth] = useState(null);
    const handleCurtain = (month, start, end) => {
        if (!month) {
            setActiveMonth();
            setShow(false);
        } else {
            if (activeMonth) {
                setActiveMonth();
                setShow(false);
            } else {
                setActiveMonth(month === activeMonth ? null : month);
                setShow(true);
                // fetch api call
                fetchDaysReport(start, end)
            }
        }
    }

    // Curtain end

    // For Calculation High And Low
    function checkHighOrLow(target, current) {

        return (
            <>
                {+current > +target ? (
                    <div className={styles.overview}>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_11772_5052)">
                                <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                            </g>
                        </svg>
                        <span className={styles.overviewTxtRed}>{current - target}</span>
                    </div>
                ) : (
                    <div className={styles.overview}>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_11762_3315)">
                                <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                            </g>
                        </svg>

                        <span className={styles.overviewTxt}>{target - current}</span>
                    </div>

                )}
            </>
        )
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>API's<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    // Listing Api (Months)
    const [tableData, setTableData] = useState([])
    const fetchPropertyReports = async () => {

        const formData = new FormData();
        formData.append("property_id", "18");
        formData.append("year", "2024");

        const requestOptions = {
            method: "POST",
            body: formData,
            redirect: "follow"
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/reports-monthly`, requestOptions)
            const data = await response.json();
            if (data.status) {
                setTableData(data)
            }

        } catch (error) {
            console.error(error)
        }
    }
    console.log(tableData?.tableContent, "mainData")

    // Listing Api (Days)
    const [daysData, setDaysData] = useState('')
    const fetchDaysReport = async (start, end) => {
        const requestOptions = {
            method: "POST",
            redirect: "follow"
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/reports-monthly-detailed?property_id=4&year=2024&start_date=${start}&end_date=${end}`, requestOptions);
            const data = await response.json();
            if (data.success) {
                setDaysData(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    console.log(daysData.tableContent, "day Data");

    // Setting List Api
    const [settingsData, setSettingsData] = useState([""])
    const fetchPropertySettings = async () => {

        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings`, requestOptions)
            const data = await response.json();
            if (data.success) {
                setSettingsData(data.data)
            }

        } catch (error) {
            console.error(error)
        }
    }


    // Settings Add/Update Api
    const [addData, setAddData] = useState({
        property_margin: "",
        buffer_margin: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddData((prevData) => ({
            ...prevData,
            [name]: value, // Update the specific input field based on its name
        }));
    };

    const AddSettings = async (e) => {
        e.preventDefault()
        const requestOptions = {
            method: "POST",
            redirect: "follow"
        };
        try {
            const url = addData.id
                ? `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings?property_margin=${addData.property_margin}&buffer_margin=${addData.buffer_margin}&id=${addData.id}`
                : `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings?property_margin=${addData.property_margin}&buffer_margin=${addData.buffer_margin}`
            const response = await fetch(url, {
                ...requestOptions,
                body: JSON.stringify({
                    property_margin: addData.property_margin,
                    buffer_margin: addData.buffer_margin,
                }),
            });

            const data = await response.json();
            if (data.success) {
                showSuccessToast(addData.id ? "Settings Updated Successfully" : "Settings Added Successfully");
                handleSwitch();
                fetchPropertySettings();
            }
        } catch (error) {
            console.log(error)
        }
    }



    // Settings Delete Api
    const deleteSettings = async (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "ids": [
                id
            ]
        });

        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/property-settings`, requestOptions);
            const data = await response.json();
            if (data.success) {
                fetchPropertySettings()
                showSuccessToast('Settings Deleted')
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        fetchPropertyReports()
    }, [])
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>API's End<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


    // Setting Popup
    const [settings, setSettings] = useState(false)
    const handleSettings = () => {
        setSettings(!settings)
    }

    // Switch Form
    const [switchForm, setSwitchForm] = useState(false)
    const handleSwitch = () => {
        setSwitchForm(!switchForm)
    }

    return (
        <div className={styles.targetsMain}>
            <h4>Yearly Targets</h4>

            <div onClick={() => {
                handleSettings();
                fetchPropertySettings();
            }} className={styles.settingsIcon}>
                <SvgSettings />
            </div>
            {settings && (
                <div className='bg-backdrop'>
                    <div className={styles.settingsMain}>
                        <div className={`${styles.settingList} ${switchForm ? styles.listHide : styles.listShow}`}>
                            <div className='flex align_items_center justify_content_between'>
                                <div className='flex align_items_center flex_gap_10'>
                                    <span onClick={handleSettings}>
                                        <SvgBack />
                                    </span>
                                    <h4>Settings</h4>
                                </div>
                                <PrimaryButton size={"medium"} onClick={handleSwitch} title={"Add"} />
                            </div>
                            <table className={styles.settingsWrapper}>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Buffer</th>
                                        <th>Property Margin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {settingsData && settingsData.length > 0 && settingsData.map((items, index) =>
                                        <tr key={index} className={styles.settingsTableTr}>
                                            <td>{index + 1}</td>
                                            <td className={styles.settingTableTd}>{items.buffer_margin}</td>
                                            <td>{items.property_margin}</td>
                                            <td>
                                                <span className='pointer' onClick={() => {
                                                    setAddData({
                                                        id: items.id,
                                                        property_margin: items.property_margin,
                                                        buffer_margin: items.buffer_margin
                                                    });
                                                    handleSwitch()
                                                }}>
                                                    <SvgEdit />
                                                </span>
                                            </td>
                                            <td>
                                                <span className='pointer' onClick={() => deleteSettings(items.id)}>
                                                    <SvgDelete />
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className={`${styles.settingsAdd} ${switchForm ? styles.addShow : styles.addHide}`}>
                            <div className='flex align_items_center flex_gap_10 mb-10'>
                                <span onClick={() => {
                                    handleSwitch();
                                    setAddData({ property_margin: "", buffer_margin: "" })
                                }}>
                                    <SvgBack />
                                </span>
                                <h4>Add Settings</h4>
                            </div>
                            <div>
                                <PrimaryInput onChange={handleChange} type={"number"} name={"buffer_margin"} value={addData.buffer_margin} size={"medium"} label={"Buffer"} />
                            </div>
                            <div>
                                <PrimaryInput onChange={handleChange} type={"number"} name={"property_margin"} value={addData.property_margin} size={"medium"} label={"Property Margin"} />
                            </div>
                            <div>
                                <PrimaryButton onClick={(e) => AddSettings(e)} size={"medium"} title={"Add"} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className={`${styles.graphsWrapper} mt-10`}>
                {tableData && tableData?.graphData?.length > 0 && tableData.graphData.map((items, index) => {
                    {/* {graphData.map((items,index)=>{ */ }
                    const data = {
                        labels: items.GraphCurr,
                        datasets: [
                            {
                                label: "Items",
                                data: items.GraphCurr,
                                borderColor: items.color,
                                backgroundColor: items.color,
                                tension: 0.4,
                                pointRadius: 0,
                                // fill:items.color
                                // pointHoverRadius: 0, 
                            }
                        ]
                    }
                    const options = {
                        plugins: {
                            legend: {
                                display: false, // Disable legend (label box)
                            },
                        },
                        scales: {
                            x: {
                                display: false,
                                grid: {
                                    display: false, // Hide grid lines on x-axis
                                },
                            },
                            y: {
                                display: false,
                                grid: {
                                    display: false, // Hide grid lines on y-axis
                                },
                            },
                        },
                    }
                    return (

                        <div key={index} className={`${styles.targetsGraphs} ${styles.whiteWrapper}`}>
                            <div className={styles.graphHeading}>
                                <h5>{items.title}</h5>
                                <h2>{items.percentage}</h2>
                            </div>
                            <div className={styles.graphImg}>
                                <Line options={options} data={data} />
                            </div>
                        </div>
                    )
                }
                )}
            </div>

            <div className={`${styles.tableWrapper}`}>
                <table className={`${styles.tableMain}`}>
                    <thead>
                        <tr>
                            {tableHeading.map((items, index) =>
                                <th className={`${styles.tableHeading}`} key={index}>{items.heading}</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {tableData && tableData?.tableContent?.length > 0 && tableData?.tableContent.map((items, index) =>
                            // {tableContent.map((items,index)=>
                            <tr key={index}>
                                <td style={{ borderLeft: "none" }} className={`${styles.tableDataTd} ${styles.tableDataMonth} ${activeMonth === items.month ? styles.active : ""}`}>
                                    <div>{items.month}</div>
                                   
                                    <div onClick={() => handleCurtain(items.month, items.start_date, items.end_date)}>
                                        <svg style={activeMonth === items.month ? { transform: "rotate(180deg)" } : {}} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="8" cy="8.5" r="7.5" fill={activeMonth === items.month ? "#3968ED" : "white"} stroke={activeMonth === items.month ? "" : "#3968ED"} />
                                            <path d="M6.40039 5.69995L9.20039 8.49995L6.40039 11.3" stroke={activeMonth === items.month ? "white" : "#3968ED"} strokeWidth="1.2" />
                                        </svg>
                                    </div>
                                </td>

                                <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                        <table className={styles.innerTable}>
                                            <tbody>
                                                <tr>
                                                    <td className={styles.innerHeading}>Targeted</td>
                                                    <td className={styles.innerHeading}>Achieved</td>
                                                </tr>
                                                <tr>
                                                    <td className={styles.innerTd}>{(items.occTrg).toFixed(2)}</td>
                                                    <td className={styles.innerTd}>{(items.occCurr).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* {checkHighOrLow((items.occTrg),(items.occCurr))} */}
                                        {items.occTrg > items.occCurr ? (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11772_5052)">
                                                        <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                    </g>
                                                </svg>
                                                <span className={styles.overviewTxtRed}>{(Number(items.occDiff)).toFixed(2)}</span>
                                            </div>
                                        ) : (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11762_3315)">
                                                        <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                    </g>
                                                </svg>

                                                <span className={styles.overviewTxt}>{(Number(items.occDiff)).toFixed(2)}</span>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                        <table className={styles.innerTable}>
                                            <tbody>
                                                <tr>
                                                    <td className={styles.innerHeading}>Targeted</td>
                                                    <td className={styles.innerHeading}>Achieved</td>
                                                </tr>
                                                <tr>
                                                    <td className={styles.innerTd}>{(items.adrTrg).toFixed(2)}</td>
                                                    <td className={styles.innerTd}>{(items.adrCurr).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* {checkHighOrLow((items.adrTrg),(items.adrCurr))} */}
                                        {items.adrTrg > items.adrCurr ? (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11772_5052)">
                                                        <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                    </g>
                                                </svg>
                                                <span className={styles.overviewTxtRed}>{(Number(items.adrDiff)).toFixed(2)}</span>
                                            </div>
                                        ) : (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11762_3315)">
                                                        <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                    </g>
                                                </svg>

                                                <span className={styles.overviewTxt}>{(Number(items.adrDiff)).toFixed(2)}</span>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                        <table className={styles.innerTable}>
                                            <tbody>
                                                <tr>
                                                    <td className={styles.innerHeading}>Targeted</td>
                                                    <td className={styles.innerHeading}>Achieved</td>
                                                </tr>
                                                <tr>
                                                    <td className={styles.innerTd}>{(items.parTrg).toFixed(2)}</td>
                                                    <td className={styles.innerTd}>{(items.parCurr).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* {checkHighOrLow((items.parTrg),(items.parCurr))} */}
                                        {items.parTrg > items.parCurr ? (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11772_5052)">
                                                        <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                    </g>
                                                </svg>
                                                <span className={styles.overviewTxtRed}>{(Number(items.parDiff)).toFixed(2)}</span>
                                            </div>
                                        ) : (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11762_3315)">
                                                        <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                    </g>
                                                </svg>

                                                <span className={styles.overviewTxt}>{(Number(items.parDiff)).toFixed(2)}</span>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td className={`${styles.tableDataTd}`}>
                                    <div className={styles.innerData}>
                                        <table className={styles.innerTable}>
                                            <tbody>
                                                <tr>
                                                    <td className={styles.innerHeading}>Targeted</td>
                                                    <td className={styles.innerHeading}>Achieved</td>
                                                </tr>
                                                <tr>
                                                    <td className={styles.innerTd}>{(items.revTrg).toFixed(2)}</td>
                                                    <td className={styles.innerTd}>{(items.revCurr).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* {checkHighOrLow((items.revTrg),(items.revCurr))} */}
                                        {items.revTrg > items.revCurr ? (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11772_5052)">
                                                        <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                    </g>
                                                </svg>
                                                <span className={styles.overviewTxtRed}>{(Number(items.revDiff)).toFixed(2)}</span>
                                            </div>
                                        ) : (
                                            <div className={styles.overview}>
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                        <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11762_3315)">
                                                        <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                    </g>
                                                </svg>

                                                <span className={styles.overviewTxt}>{(Number(items.revDiff)).toFixed(2)}</span>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>


                <div className={`${styles.curtainMain} ${(show === false) ? styles.slideOff : show === true ? styles.slideOn : ""}`} >
                    <CurtainTable handleClose={handleCurtain}>
                        <table className={`${styles.tableMain} ${styles.curtainTable_main}`}>
                            <thead>
                                <tr>
                                    {tableHeadingSlider.map((items, index) =>
                                        <th className={`${styles.tableHeading}`} key={index}>{items.heading}</th>
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {daysData && daysData?.tableContent?.length > 0 && daysData.tableContent.map((items, index) =>
                                    // {tableContent.map((items,index)=>
                                    <tr key={index}>
                                        <td style={{ borderLeft: "none" }} className={`${styles.tableDataTd} ${styles.tableDataMonth}`}>
                                            <div>{getDateAndMonth(items.date)}</div>
                                        </td>

                                        <td className={`${styles.tableDataTd}`}>
                                            <div className={styles.innerData}>
                                                <table className={styles.innerTable}>
                                                    <tbody>
                                                        <tr>
                                                            <td className={styles.innerHeading}>Targeted</td>
                                                            <td className={styles.innerHeading}>Achieved</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={styles.innerTd}>{(items.occTrg).toFixed(2)}</td>
                                                            <td className={styles.innerTd}>{(items.occCurr).toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* {checkHighOrLow((items.occTrg),(items.occCurr))} */}
                                                {items.occTrg > items.occCurr ? (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11772_5052)">
                                                                <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                            </g>
                                                        </svg>
                                                        <span className={styles.overviewTxtRed}>{(Number(items.occDiff)).toFixed(2)}</span>
                                                    </div>
                                                ) : (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11762_3315)">
                                                                <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                            </g>
                                                        </svg>

                                                        <span className={styles.overviewTxt}>{(Number(items.occDiff)).toFixed(2)}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className={`${styles.tableDataTd}`}>
                                            <div className={styles.innerData}>
                                                <table className={styles.innerTable}>
                                                    <tbody>
                                                        <tr>
                                                            <td className={styles.innerHeading}>Targeted</td>
                                                            <td className={styles.innerHeading}>Achieved</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={styles.innerTd}>{(items.adrTrg).toFixed(2)}</td>
                                                            <td className={styles.innerTd}>{(items.adrCurr).toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* {checkHighOrLow((items.adrTrg),(items.adrCurr))} */}
                                                {items.adrTrg > items.adrCurr ? (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11772_5052)">
                                                                <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                            </g>
                                                        </svg>
                                                        <span className={styles.overviewTxtRed}>{(Number(items.adrDiff)).toFixed(2)}</span>
                                                    </div>
                                                ) : (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11762_3315)">
                                                                <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                            </g>
                                                        </svg>

                                                        <span className={styles.overviewTxt}>{(Number(items.adrDiff)).toFixed(2)}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className={`${styles.tableDataTd}`}>
                                            <div className={styles.innerData}>
                                                <table className={styles.innerTable}>
                                                    <tbody>
                                                        <tr>
                                                            <td className={styles.innerHeading}>Targeted</td>
                                                            <td className={styles.innerHeading}>Achieved</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={styles.innerTd}>{(items.parTrg).toFixed(2)}</td>
                                                            <td className={styles.innerTd}>{(items.parCurr).toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* {checkHighOrLow((items.parTrg),(items.parCurr))} */}
                                                {items.parTrg > items.parCurr ? (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11772_5052)">
                                                                <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                            </g>
                                                        </svg>
                                                        <span className={styles.overviewTxtRed}>{(Number(items.parDiff)).toFixed(2)}</span>
                                                    </div>
                                                ) : (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11762_3315)">
                                                                <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                            </g>
                                                        </svg>

                                                        <span className={styles.overviewTxt}>{(Number(items.parDiff)).toFixed(2)}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td className={`${styles.tableDataTd}`}>
                                            <div className={styles.innerData}>
                                                <table className={styles.innerTable}>
                                                    <tbody>
                                                        <tr>
                                                            <td className={styles.innerHeading}>Targeted</td>
                                                            <td className={styles.innerHeading}>Achieved</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={styles.innerTd}>{(items.revTrg).toFixed(2)}</td>
                                                            <td className={styles.innerTd}>{(items.revCurr).toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* {checkHighOrLow((items.revTrg),(items.revCurr))} */}
                                                {items.revTrg > items.revCurr ? (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11772_5052" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect x="18" y="18.5" width="18" height="18" transform="rotate(-180 18 18.5)" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11772_5052)">
                                                                <path d="M12.9767 7.25002C13.1337 7.25002 13.2602 7.30747 13.3561 7.42236C13.452 7.53726 13.5 7.6713 13.5 7.82449C13.5 7.86279 13.4477 7.99683 13.343 8.22662L9.54942 12.3915C9.46221 12.4873 9.375 12.5543 9.28779 12.5926C9.20058 12.6309 9.10465 12.65 9 12.65C8.89535 12.65 8.79942 12.6309 8.71221 12.5926C8.625 12.5543 8.53779 12.4873 8.45058 12.3915L4.65698 8.22662C4.60465 8.16917 4.56541 8.10694 4.53925 8.03992C4.51308 7.9729 4.5 7.90109 4.5 7.82449C4.5 7.6713 4.54797 7.53726 4.6439 7.42236C4.73983 7.30747 4.86628 7.25002 5.02326 7.25002L12.9767 7.25002Z" fill="#E03838" />
                                                            </g>
                                                        </svg>
                                                        <span className={styles.overviewTxtRed}>{(Number(items.revDiff)).toFixed(2)}</span>
                                                    </div>
                                                ) : (
                                                    <div className={styles.overview}>
                                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <mask id="mask0_11762_3315" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
                                                                <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                                                            </mask>
                                                            <g mask="url(#mask0_11762_3315)">
                                                                <path d="M5.02326 11.75C4.86628 11.75 4.73983 11.6925 4.6439 11.5776C4.54797 11.4627 4.5 11.3287 4.5 11.1755C4.5 11.1372 4.55233 11.0032 4.65698 10.7734L8.45058 6.60849C8.53779 6.51274 8.625 6.44572 8.71221 6.40742C8.79942 6.36912 8.89535 6.34998 9 6.34998C9.10465 6.34998 9.20058 6.36912 9.28779 6.40742C9.375 6.44572 9.46221 6.51274 9.54942 6.60849L13.343 10.7734C13.3953 10.8308 13.4346 10.8931 13.4608 10.9601C13.4869 11.0271 13.5 11.0989 13.5 11.1755C13.5 11.3287 13.452 11.4627 13.3561 11.5776C13.2602 11.6925 13.1337 11.75 12.9767 11.75H5.02326Z" fill="#15AA12" />
                                                            </g>
                                                        </svg>

                                                        <span className={styles.overviewTxt}>{(Number(items.revDiff)).toFixed(2)}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </CurtainTable>
                </div>
            </div>
        </div>

    )
}

export default PropertyTargets



import React, { useEffect, useRef } from 'react';
import styles from './NavTabs.module.scss';
import { NavLink, useLocation } from 'react-router-dom';

const NavTabs = ({ mainData=[], type }) => {
  const location = useLocation();
  const activeTabRef = useRef(null);

  // Effect to ensure the active tab scrolls into view
  
  useEffect(() => {
    if (activeTabRef.current) {
      activeTabRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [location.pathname]);

  return (
    <div className={styles.tabsMain}>
      <div className={styles.tabsWrapper} >
        {(!type || type === 'icon') && (
          <>
            {mainData.map((items, index) => (
              <div key={index} className={`${styles.tabsCards} ${styles.underline}`}>
                <NavLink
                  to={items.to}
                  className={({ isActive }) => ` ${isActive ? styles.underlineActive : ''} ${styles.underHover} `}
                  ref={location.pathname.includes(items.to) ? activeTabRef : null}
                >
                  {type === 'icon' && (
                    <span
                      className={`${
                        location.pathname.includes(items.to)
                          ? styles.btn_icon_underline
                          : styles.btn_icon_de
                      }`}
                    >
                      {items.icon}
                    </span>
                  )}
                  {items.label}
                </NavLink>
              </div>
            ))}
          </>
        )}
        {type === 'button' && (
          <>
            {mainData.map((items, index) => (
              <div
                style={{ marginRight: '0' }}
                key={index}
                className={`${styles.tabsCards} ${styles.solidBackground}`}
              >
                <NavLink
                  to={items.to}
                  className={({ isActive }) => `${isActive ? styles.solidActive : ''} ${styles.solidHover}`}
                  ref={location.pathname.includes(items.to) ? activeTabRef : null}
                >
                  {items.label}
                </NavLink>
              </div>
            ))}
          </>
        )}
        {type === 'btnWithIcon' && (
          <>
            {mainData.map((items, index) => (
              <div
                style={{ marginRight: '0' }}
                key={index}
                className={`${styles.tabsCards} ${styles.solidBackground}`}
              >
                <NavLink
                  to={items.to}
                  className={({ isActive }) => `${isActive ? styles.solidActive : ''}  ${styles.solidHover} `}
                  ref={location.pathname.includes(items.to) ? activeTabRef : null}
                >
                  <span
                    className={`${
                      location.pathname.includes(items.to)
                        ? styles.btn_icon_active
                        : styles.btn_icon_de
                    }`}
                  >
                    {items.icon}
                  </span>
                  {items.label}
                </NavLink>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default NavTabs;

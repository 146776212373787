import React, { useState } from "react";
import styles from "./StepperForm.module.scss";
import { SvgCancel } from "../svgIcons/Icons";
import {
  PrimaryButton,
  SecondaryButton,
} from "../CustomComponents/NewCustomComp/Buttons/Buttons";
import GeneralDetails from "./GeneralDetails";
import ContactAndAddress from "./ContactAndAddress";
import RoomsSetup from "./RoomsSetup";
import MealPlansSetup from "./MealPlansSetup";
import SelectPlansSetup from "./SelectPlansSetup";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { getDecryptedData } from "../../assets/encryptStorage";
import Loader from "../UI/Loader/Loader";

const steps = [
  { number: 1, name: "General Details" },
  { number: 2, name: "Contact & Address" },
  { number: 3, name: "Rooms" },
  { number: 4, name: "Meal Plans" },
  { number: 5, name: "Select Plan" },
];

const PropertySetupStepperForm = ({ setShowAddProperty, customerId,refreshData }) => {
  // const userToken = localStorage.getItem("userToken");
  const userToken = getDecryptedData("encryptToken");

  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep === 0) {
      if (
        !formData.propertyLegalName ||
        !formData.propertyType ||
        !formData.propertyRating ||
        !formData.propertyArea ||
        // !formData.checkInTime ||
        // !formData.checkOutTime ||
        !formData.propertyDetails
      ) {
        setCurrentStep(0);
        showErrorToast("Please Fill All Required Fields");
        return;
      }
    }

    if (currentStep === 1) {
      if (
        !formDataStepTwo.phone ||
        !formDataStepTwo.email ||
        !formDataStepTwo.city ||
        !formDataStepTwo.state ||
        !formDataStepTwo.country ||
        !formDataStepTwo.zipCode ||
        !formDataStepTwo.address
      ) {
        setCurrentStep(1);
        showErrorToast("Please Fill All Required Fields");
        return;
      }
    }

    if (currentStep === 2 && roomsArray.length === 0) {
      setCurrentStep(2);
      showErrorToast("Please Add at least One Room");
      return;
    }

    if (currentStep === 3 && roomsPlanArray.length === 0) {
      setCurrentStep(3);
      showErrorToast("Please Add at least One Meal Plan");
      return;
    }

    // Proceed to the next step if all checks are passed
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const progress = ((currentStep + 1) / steps.length) * 100;

  // step 0 functions
  const [formData, setFormData] = useState({
    propertyLegalName: "",
    propertyType: "",
    propertyRating: "",
    propertyArea: "",
    checkInTime: "",
    checkOutTime: "",
    propertyDetails: "",
  });

  // step 1 functions

  const [formDataStepTwo, setFormDataStepTwo] = useState({
    phone: "",
    email: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    address: "",
  });

  // step 2 functions

  const [roomData, setRoomData] = useState({
    categoryName: "",
    totalRooms: "",
    rackPrice: "",
    extraAdults: "",
    childWithBed: "",
    childWithoutBed: "",
  });
  const [roomsArray, setRoomsArray] = useState([]);

  // step 3 functions

  const [roomPlanData, setRoomPlanData] = useState({
    planType: "",
    planName: "",
    breakFast: "",
    lunch: "",
    dinner: "",
  });

  const [roomsPlanArray, setRoomsPlanArray] = useState([]);

  // step 4 functions
  const [isTrial, setIsTrial] = useState(false);
  const [formDataStepFour, setFormDataStepFour] = useState({
    plan_id: "",
    frequency: "",
    trial_days: "",
  });
  const [loader, setLoader] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);
    const raw = JSON.stringify({
      account_id: customerId,
      property_email: formDataStepTwo.email,
      property_name: formData.propertyLegalName,
      property_phone: formDataStepTwo.phone,
      property_description: formData.propertyDetails,
      property_city: formDataStepTwo.city,
      property_address: formDataStepTwo.address,
      property_country: formDataStepTwo.country,
      property_state: formDataStepTwo.state,
      property_zip: formDataStepTwo.zipCode,
      property_hotel_type: formData.propertyType,
      trial: isTrial,
      trial_days: formDataStepFour.trial_days ? formDataStepFour.trial_days : 0,
      plan_id: formDataStepFour.plan_id,
      frequency: formDataStepFour.frequency,
      payment_status: "unpaid",
      rooms: roomsArray.map((item) => ({
        room_name: item.categoryName,
        room_total: item.totalRooms,
        rack_price: item.rackPrice,
        extra_adults: item.extraAdults,
        child_with_Bed: item.childWithBed,
        child_without_Bed: item.childWithoutBed,
      })),
      meal_plans: roomsPlanArray.map((item) => ({
        meal_plan_type: item.planType,
        meal_plan_name: item.planName,
        breakfast: item.breakFast ? "included" : "excluded",
        breakfast_cost: item.breakFast ? item.breakFast : 0,
        lunch: item.lunch ? "included" : "excluded",
        lunch_cost: item.lunch ? item.lunch : 0,
        dinner: item.dinner ? "included" : "excluded",
        dinner_cost: item.dinner ? item.dinner : 0,
      })),
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/add-property-setup`,
        {
          method: "POST",
          body: raw,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setShowAddProperty(false);
        setFormData({});
        setFormDataStepTwo({});
        setRoomData({});
        setRoomsArray([]);
        setRoomPlanData({});
        setRoomsPlanArray([]);
        setFormDataStepFour({});
        setLoader(false);
        refreshData()
      } else {
        showErrorToast(data.error);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className={styles["stepper-form"]}>
          <div className="room-type-heading mb-10 flex justify_content_between">
            Setup Your Property
            <span className="pointer" onClick={() => setShowAddProperty(false)}>
              <SvgCancel />
            </span>
          </div>

          <hr className="mb-10 mt-10" />

          <div className={styles.stepsList}>
            {steps.map((step, index) => (
              <div
                key={step.number}
                className={`${styles.step} ${
                  currentStep === index ? styles.activeStep : ""
                }`}
              >
                <span
                  className={`${styles.stepNumber} ${
                    currentStep > index ? styles.completed : ""
                  } ${currentStep >= index ? styles.active : ""}`}
                >
                  {currentStep > index ? (
                    <>
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.61664 6.1L9.26664 0.45C9.39998 0.316667 9.55553 0.25 9.73331 0.25C9.91109 0.25 10.0666 0.316667 10.2 0.45C10.3333 0.583333 10.4 0.741667 10.4 0.925C10.4 1.10833 10.3333 1.26667 10.2 1.4L4.08331 7.53333C3.94998 7.66667 3.79442 7.73333 3.61664 7.73333C3.43886 7.73333 3.28331 7.66667 3.14998 7.53333L0.283309 4.66667C0.149975 4.53333 0.0860864 4.375 0.0916419 4.19167C0.0971975 4.00833 0.166642 3.85 0.299975 3.71667C0.433309 3.58333 0.591642 3.51667 0.774975 3.51667C0.958309 3.51667 1.11664 3.58333 1.24998 3.71667L3.61664 6.1Z"
                          fill="white"
                        />
                      </svg>
                    </>
                  ) : (
                    step.number
                  )}
                </span>
                <span>{step.name}</span>
              </div>
            ))}
          </div>

          <div className={styles["progress-bar"]}>
            <div
              className={styles.progress}
              style={{ width: `${progress}%` }}
            ></div>
          </div>

          <div className={styles.stepContent}>
            {currentStep === 0 && (
              <GeneralDetails formData={formData} setFormData={setFormData} />
            )}
            {currentStep === 1 && (
              <ContactAndAddress
                formData={formDataStepTwo}
                setFormData={setFormDataStepTwo}
              />
            )}
            {currentStep === 2 && (
              <RoomsSetup
                roomData={roomData}
                setRoomData={setRoomData}
                rooms={roomsArray}
                setRooms={setRoomsArray}
              />
            )}
            {currentStep === 3 && (
              <MealPlansSetup
                roomData={roomPlanData}
                setRoomData={setRoomPlanData}
                rooms={roomsPlanArray}
                setRooms={setRoomsPlanArray}
              />
            )}
            {currentStep === 4 && (
              <SelectPlansSetup
                isTrial={isTrial}
                setIsTrial={setIsTrial}
                formDataStepFour={formDataStepFour}
                setFormDataStepFour={setFormDataStepFour}
              />
            )}
          </div>

          <div className="flex justify_content_between">
            <SecondaryButton
              onClick={previousStep}
              disabled={currentStep === 0}
              title={"Previous"}
              size="medium"
            />

            <PrimaryButton
              onClick={
                currentStep === steps.length - 1 ? handleSubmit : nextStep
              }
              title={currentStep === steps.length - 1 ? "Done" : "Next"}
              size="medium"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PropertySetupStepperForm;

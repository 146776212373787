import React, { useState } from "react";
import { PrimaryInput } from "../CustomComponents/NewCustomComp/Inputs/Inputs";
import { Textarea } from "../CustomComponents/NewCustomComp/Testarea/Textarea";

const ContactAndAddress = ({formData, setFormData}) => {
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      <div className="flex align_items_center flex_gap_10">
        <div className="w-half">
          <PrimaryInput
          type={'number'}
            size={"medium"}
            label={"Phone"}
            style={{ marginTop: "5px" }}
            value={formData.phone}
            name={"phone"}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-half">
          <PrimaryInput
            type={'email'}
            size={"medium"}
            label={"Email"}
            style={{ marginTop: "5px" }}
            value={formData.email}
            name={"email"}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex align_items_center flex_gap_10 mb-10 mt-10">
        <div className="w-half">
          <PrimaryInput
            type={'text'}
            size={"medium"}
            label={"City"}
            style={{ marginTop: "5px" }}
            value={formData.city}
            name={"city"}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-half">
          <PrimaryInput
            type={'text'}
            size={"medium"}
            label={"State"}
            style={{ marginTop: "5px" }}
            value={formData.state}
            name={"state"}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex align_items_center flex_gap_10">
        <div className="w-half">
          <PrimaryInput
            type={'text'}
            size={"medium"}
            label={"Country"}
            style={{ marginTop: "5px" }}
            value={formData.country}
            name={"country"}
            onChange={handleInputChange}
          />
        </div>
        <div className="w-half">
          <PrimaryInput
          type={"number"}
            size={"medium"}
            label={"Zip Code"}
            style={{ marginTop: "5px" }}
            value={formData.zipCode}
            name={"zipCode"}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="w-full mt-10 mb-10">
      <PrimaryInput
          type={"text"}
            size={"medium"}
            label={"Address"}
            style={{ marginTop: "5px" }}
            value={formData.address}
            name={"address"}
            onChange={handleInputChange}
          />
      </div>
    </>
  );
};

export default ContactAndAddress;

import React, { useEffect } from 'react'
import { getDecryptedData } from '../../assets/encryptStorage';
import { useLocation, useParams } from 'react-router-dom';
import styles from "./Settings.module.scss";
import { PrimaryButton, SecondaryButton } from '../../Components/CustomComponents/NewCustomComp/Buttons/Buttons';
import { SvgCancel, SvgDelete } from '../../assets/svgIcons';
import ToggleSwitch from '../../Components/CustomComponents/NewCustomComp/Switch/ToggleSwitch';
import { PrimaryInput } from '../../Components/CustomComponents/NewCustomComp/Inputs/Inputs';
import { showSuccessToast } from '../../assets/toastUtils';

const PaymentGateway = ({ handleSettings }) => {

  const [testMode, setTestMode] = React.useState(false);
  const [fieldValues, setFieldValues] = React.useState({});
  const userToken = getDecryptedData("encryptToken")
  const { customerId } = useParams();


  const [paymentGateways, setPaymentGateways] = React.useState([]);
  const fetchPaymentGateways = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/account-gateway?account_id=${id}`, requestOptions)
      const data = await response.json();
      if (data.success) {
        setPaymentGateways(data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }



  useEffect(() => {
    if (customerId) {
      fetchPaymentGateways(customerId)
    }
  }, [customerId])

  const [selectedGateway, setSelectedGateway] = React.useState(null);


  const gatewayFields = {
    default: ["Key", "Salt Key", "Default Fee Amount"],
    razorpay: ["Key", "Salt Key"],
    nttdata: [
      "Request Encryption Key",
      "Request Salt Key",
      "Response Encryption Key",
      "Response Salt Key",
      "Atom Product",
      "Atom Login ID",
      "Atom Password",
    ],
  };
  const normalizeGatewayName = (gateway) =>
    gateway && gateway.gateway_name?.toLowerCase().replace(/\s+/g, "") || "default";

  const handleInputChange = (field, value) => {
    setFieldValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleCancel = () => {
    setSelectedGateway(null);
    setFieldValues({});
  };

  const handleGatewayUpdate = async (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const payload = {
      gateway_name: selectedGateway?.gateway_name,
      account_id: selectedGateway?.id,
      is_active: true,
      is_test: testMode,
    };

    (gatewayFields[normalizeGatewayName(selectedGateway)] ||
      gatewayFields.default).forEach((field) => {
        const fieldKey = field.replace(/\s+/g, "_").toLowerCase();
        payload[`${fieldKey}_information`] = fieldValues[field] || "N/A";
      });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: "follow"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/account-gateway`, requestOptions)
      const data = await response.json();
      if (data.success) {
        alert("save")
      }
    } catch (error) {

    }
  }

  const handleEditGateway = (gateway) => {
    setSelectedGateway(gateway);
    setTestMode(gateway.is_test);
    const activeData = gateway?.additional_details
    if (activeData) {
      const gatewayKey = normalizeGatewayName(gateway);
      const fieldsToSet =
        gatewayFields[gatewayKey] || gatewayFields.default;
      //  const fieldsToSet =  gatewayFields["nttdata"] ;

      const prefilledValues = {};
      fieldsToSet.forEach((field) => {
        const fieldKey = field.replace(/\s+/g, "_").toLowerCase();
        if (activeData[fieldKey]) {
          prefilledValues[field] = activeData[fieldKey];
        }
      });
      setFieldValues(prefilledValues);
    }
  }



  // Delete Gateway Api
  const handleDeleteGateway = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const raw = JSON.stringify({
      "ids": [
        id
      ]
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/account-gateway`, requestOptions)
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Deleted Successfully")
        fetchPaymentGateways(customerId)
      }
    } catch (error) {
      console.error(error)
    }
  }



  return (
    <div className={styles.gatewayContainer}>
      <div className='flex justify_content_between'>
        <h4>Payment Gateways</h4>
        <span onClick={handleSettings} className={styles.cancelIcon}><SvgCancel color={"#000"} /></span>
      </div>


      {!selectedGateway && (
        <table className={styles.gatewayTable}>
          <thead>
            <tr>
              <th>Gateway Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {paymentGateways?.map((item, index) => (
              <tr key={index}>
                <td>{item?.gateway_name}</td>
                <td>{item?.is_active ? "Active" : "Inactive"}</td>
                <td>
                  <div className='flex align_items_center flex_gap_10'>
                  <PrimaryButton title="Manage" size={"small"} onClick={() => handleEditGateway(item)} />

                    <span onClick={() => handleDeleteGateway(item.id)}  className='flex align_items_center pointer'>
                      <SvgDelete />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}





      {selectedGateway && (
        <form className={styles.updateGateway} onSubmit={handleGatewayUpdate}>
          {(gatewayFields[normalizeGatewayName(selectedGateway)] ||
            gatewayFields.default).map((field, index) => (
              <div key={index} className={styles.inputGroup}>
                <label htmlFor={field}>{field}</label>
                <PrimaryInput
                  size="medium"
                  type="text"
                  id={field}
                  placeholder={`Enter ${field}`}
                  className={styles.input}
                  value={fieldValues[field] || ""}
                  onChange={(e) => handleInputChange(field, e.target.value)}
                />
              </div>
            ))}

          <div className={styles.inputGroup}>
            <label htmlFor="Test Mode">Test Mode</label>
            <ToggleSwitch
              size="medium"
              isOn={testMode}
              handleToggle={() => setTestMode(!testMode)}
            />
          </div>

          <div className="flex flex_gap_10 mt-10">
            <PrimaryButton size="medium" title="Save" type={"submit"} />
            <SecondaryButton
              size="medium"
              title="Cancel"
              onClick={handleCancel}
            />
          </div>
        </form>
      )}
    </div>
  )
}

export default PaymentGateway

import React, { useState } from "react";
import { PrimaryInput } from "../CustomComponents/NewCustomComp/Inputs/Inputs";
import { SvgDelete, SvgPlus } from "../svgIcons/Icons";
import { showErrorToast } from "../../assets/toastUtils";

const MealPlansSetup = ({roomData, setRoomData ,rooms, setRooms}) => {
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoomData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddRoom = () => {
    const { planType, planName, breakFast, lunch, dinner } = roomData;

    // Validate required fields only
    if (!planType || !planName) {
      showErrorToast("Please fill in both Plan Type and Plan Name.");
      return;
    }

    // Check for duplicates
    const isDuplicate = rooms.some((room) => room.planType === planType && room.planName === planName);
    if (isDuplicate) {
      showErrorToast("Meal plan already exists.");
      return;
    }

    // Prepare new meal plan data
    const newRoom = {
      planType,
      planName,
      breakFast: breakFast ? Number(breakFast) : null,
      lunch: lunch ? Number(lunch) : null,
      dinner: dinner ? Number(dinner) : null,
    };

    setRooms([...rooms, newRoom]);
    setRoomData({
      planType: "",
      planName: "",
      breakFast: "",
      lunch: "",
      dinner: "",
    });
  };

  const handleDeleteRoom = (index) => {
    setRooms(rooms.filter((_, i) => i !== index));
  };

  return (
    <div>
      <div className="flex flex_gap_10 align_items_end mb-10">
        <PrimaryInput
          label={"Plan Type"}
          size={"medium"}
          type={"text"}
          name="planType"
          value={roomData.planType}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Plan Name"}
          size={"medium"}
          type={"text"}
          name="planName"
          value={roomData.planName}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"BreakFast"}
          size={"medium"}
          type={"number"}
          name="breakFast"
          value={roomData.breakFast}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Lunch"}
          size={"medium"}
          type={"number"}
          name="lunch"
          value={roomData.lunch}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Dinner"}
          size={"medium"}
          type={"number"}
          name="dinner"
          value={roomData.dinner}
          onChange={handleChange}
        />

        <span className="pointer" onClick={handleAddRoom}>
          <SvgPlus />
        </span>
      </div>

      <div className="rooms-list mb-10">
        {rooms.length > 0 &&
          rooms.map((room, index) => (
            <div key={index} className="flex flex_gap_10 align_items_end mt-10">
              <PrimaryInput
                size={"medium"}
                name="planType"
                value={room.planType}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                name="planName"
                value={room.planName}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                name="breakFast"
                value={room.breakFast || "NA"}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                name="lunch"
                value={room.lunch || "NA"}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                name="dinner"
                value={room.dinner || "NA"}
                disabled={true}
              />

              <span onClick={() => handleDeleteRoom(index)} className="pointer">
                <SvgDelete />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MealPlansSetup;

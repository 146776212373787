import React from 'react'
import NavTabs from '../../Components/CustomComponents/NewCustomComp/NavTabs/NavTabs'
import { Outlet } from 'react-router-dom'

const Settings = () => {

    const mainData =[
        {label:"Payment Gateway",to:"payment-gateway"}
    ]


  return (
    <div className='main_container'>

        
      <NavTabs mainData={mainData} />


      <Outlet />
    </div>
  )
}

export default Settings

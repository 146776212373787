import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import InfiniteLoading from "../InfiniteLoading/InfiniteLoading";
import styles from "./ListingPropertyTargets.module.scss";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../CustomShopifyIndexTable/CustomShopifyIndexTable";
import AddTargetPageNew from "../../../Pages/AddTargetPageNew";
import { getDateByMonth } from "../../../assets/utils";
import Pagination from "../NewCustomComp/pagination/Pagination";
import { SvgBack, SvgDelete, SvgEdit, SvgSettings } from "../../svgIcons/Icons";
import {
  PrimaryButton,
  SecondaryButton,
} from "../NewCustomComp/Buttons/Buttons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { PrimaryInput } from "../NewCustomComp/Inputs/Inputs";
import ToggleSwitch from "../NewCustomComp/Switch/ToggleSwitch";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import { SvgCancel } from "../../../assets/svgIcons";

const ListingPropertyTargets = () => {
  const pathname = useParams();
  const { propertyId } = pathname;

  const [listTargets, setListTargets] = useState("");
  const [nextPage, setNextPage] = useState(null);
  const [addTarget, setAddTarget] = useState(false);

  const handleAddTarget = () => {
    setAddTarget(!addTarget);
  };

  const fetchListTargets = async (nextPage) => {
    const url = nextPage
      ? `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/property-target-listing?property_id=${propertyId}&page=${nextPage}`
      : `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/property-target-listing?property_id=${propertyId}`;

    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();

      if (result.success) {
        setListTargets(result);
        setNextPage(result.property_targets_list.next_page_url);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (propertyId) {
      fetchListTargets();
    }
  }, []);

  // const fetchInfiniteListing = async (nextPageUrl) => {

  //   const requestOptions = {
  //     method: "POST",
  //     redirect: "follow"
  //   };
  //   try {
  //     const response = await fetch(
  //       nextPageUrl,
  //       requestOptions
  //     );
  //     const result = await response.json();

  //     if (result.success) {
  //       setListTargets(result)
  //       setNextPage(result.property_targets_list.next_page_url)
  //     }

  //   } catch (error) {

  //   }

  // }

  const handlePageChange = (pageNumber) => {
    fetchListTargets(pageNumber);
  };

  // Settings
  const [settings, setSettings] = useState(false);
  const handleSettings = () => {
    setSettings(!settings);
  };
  // Switch Form
  const [switchForm, setSwitchForm] = useState(false);
  const [switchOccupancy, setSwitchOccupancy] = useState(false);
  const handleSwitch = () => {
    setSwitchForm(!switchForm);
  };
  const handleSwitchOccupancy = () => {
    setSwitchOccupancy(!switchOccupancy);
  };

  // Setting List Api
  const [settingsData, setSettingsData] = useState([""]);
  const fetchPropertySettings = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/property-settings`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setSettingsData(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Settings Add/Update Api
  const [addData, setAddData] = useState({
    property_margin: "",
    buffer_margin: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific input field based on its name
    }));
  };

  const AddSettings = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    try {
      const url = addData.id
        ? `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/property-settings?property_margin=${addData.property_margin}&buffer_margin=${addData.buffer_margin}&id=${addData.id}`
        : `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/property-settings?property_margin=${addData.property_margin}&buffer_margin=${addData.buffer_margin}`;
      const response = await fetch(url, {
        ...requestOptions,
        body: JSON.stringify({
          property_margin: addData.property_margin,
          buffer_margin: addData.buffer_margin,
        }),
      });

      const data = await response.json();
      if (data.success) {
        showSuccessToast(
          addData.id
            ? "Settings Updated Successfully"
            : "Settings Added Successfully"
        );
        handleSwitch();
        setAddData({ property_margin: "", buffer_margin: "" });
        fetchPropertySettings();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Settings Delete Api
  const deleteSettings = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      ids: [id],
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/property-settings`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        fetchPropertySettings();
        showSuccessToast("Settings Deleted");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Listing Recommended Occupancy
  const [recommended, setRecommended] = useState([]);
  const [switchArea, setSwitchArea] = useState("Hilly Areas");
  const handleSwitchArea = (e) => {
    setSwitchArea(e.target.value);
  };

  const getRecommended = async () => {
    const formData = new FormData();

    formData.append("area_type", switchArea);

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/occupancies`,
        requestOptions
      );
      const result = await response.json();
      if ((result.message = "Occupancies")) {
        setRecommended(result);
      } else {
        showErrorToast("Data Not Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRecommended(switchArea);
  }, [switchArea]);

  const handelOccupancyChange = (e, month) => {
    const { value } = e.target;
    const validatedValue = +value > 100 ? 100 : +value;

    const updatedOccupancies = recommended.occupancies.map((item) => {
      if (item.month === month) {
        return { ...item, occupancy_percent: validatedValue };
      }
      return item;
    });

    setRecommended({
      ...recommended,
      occupancies: updatedOccupancies, // Update occupancies with the new data
    });
  };

  // Update Occupancy
  const handleUpdateRecommended = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      occupancies: recommended?.occupancies,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/update-suggested-occupancy`,
        requestOptions
      );
      const result = await response.json();

      if (result.success) {
        showSuccessToast("Data Updated Successfully");
      } else {
        showErrorToast("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // update recommended

  const handelStatusUpdate = async (e, id, buffer, profit) => {
    console.log(e.target.checked, buffer);

    // Prepare the settings data with `is_suggested` flag based on the selected radio button
    const raw = {
      id: id,
      buffer_margin: buffer,
      property_margin: profit,
      is_suggested: e.target.checked ? 1 : 0,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(raw), // Send the array of settings
      redirect: "follow",
    };

    try {
      const url = `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/property-settings`;

      const response = await fetch(url, requestOptions);
      const data = await response.json();

      if (data.success) {
        showSuccessToast("Suggested Updated Successfully");
        // handleSwitch();
        fetchPropertySettings(); // Fetch the updated settings
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [switchTabs, setSwitchTabs] = useState("buffer");

  console.log(switchTabs);

  return (
    <div className="main_container">
      <div className={styles.whiteWrapper}>
        {/* {listTargets.map((item, index) => */}
        <div className={styles.totalData}>
          <h3>
            {" "}
            <strong>Total Yearly Targets :</strong>{" "}
            {listTargets?.targeted_yearly_earning}
          </h3>
          <h3>
            <strong>Total Expanse :</strong> {listTargets?.total_expense}
          </h3>
          <h3>
            <strong>Total No. of Rooms :</strong> {listTargets?.total_rooms}
          </h3>
          <h3>
            <strong>Profit Margin (%) :</strong>{" "}
          </h3>
        </div>
        {/* )} */}
        {/* <div onClick={handleAddTarget} className={styles.targetBtn}>
          Add Targets
        </div> */}
        <div
          onClick={() => {
            handleSettings();
            fetchPropertySettings();
          }}
          className={`${styles.settingsIcon} pointer`}
        >
          <SvgSettings />
        </div>
        {settings && (
          <div className="bg-backdrop">
            <div className={styles.settingsMain}>
              <div
                className={`${styles.settingList} ${
                  switchForm ? styles.padding : ""
                }`}
              >
                <div className="flex align_items_center justify_content_between">
                  <div className="flex align_items_center flex_gap_10">
                    <h4 style={{ fontSize: "18px", fontWeight: "600" }}>
                      Targets Settings
                    </h4>
                  </div>

                  <div className="flex flex_gap_10">
                    <span className="pointer" onClick={handleSettings}>
                      {/* <SvgBack /> */}
                      <SvgCancel
                        color={"#AAA"}
                        width={"25px"}
                        height={"25px"}
                      />
                    </span>

                    {/* <PrimaryButton size={"medium"} onClick={handleSwitch} title={"Add"} />
                    <PrimaryButton size={"medium"} onClick={() => [handleSwitchOccupancy(), getRecommended()]} title={"Occupancy"} /> */}
                  </div>
                </div>
                <div
                  className={`${styles.switchTabs_main} flex flex_gap_10 mt-10`}
                >
                  <div
                    className={`${styles.switchTabs} ${
                      switchTabs === "buffer" && styles.active
                    }`}
                    onClick={() => {
                      setSwitchTabs("buffer");
                      fetchPropertySettings();
                    }}
                  >
                    Buffer
                  </div>
                  <div
                    className={`${styles.switchTabs} ${
                      switchTabs === "occupancy" && styles.active
                    }`}
                    onClick={() => {
                      setSwitchTabs("occupancy");
                      handleSwitchOccupancy();
                      getRecommended();
                    }}
                  >
                    Occupancy
                  </div>
                </div>

                {/* Buffer */}
                {switchTabs === "buffer" && (
                  <>
                    <table className={styles.settingsWrapper}>
                      <thead>
                        <tr>
                          <th>Active</th>
                          <th>Buffer %</th>
                          <th>Profit Margin %</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {settingsData &&
                          settingsData?.length > 0 &&
                          settingsData?.map((items, index) => (
                            <tr key={index} className={styles.settingsTableTr}>
                              <td>
                                <div className="flex align_items_center flex_gap_5">
                                  <input
                                    type="radio"
                                    name="recommended"
                                    checked={items.is_suggested}
                                    onChange={(e) =>
                                      handelStatusUpdate(
                                        e,
                                        items?.id,
                                        items?.buffer_margin,
                                        items?.property_margin
                                      )
                                    }
                                  />
                                  {items.is_suggested ? (
                                    <span> Selected</span>
                                  ) : (
                                    <span> Select</span>
                                  )}
                                </div>
                              </td>
                              <td className={styles.settingTableTd}>
                                {items?.buffer_margin}
                              </td>
                              <td>{items?.property_margin}</td>
                              <td className="flex align_items_center flex_gap_10">
                                <span
                                  className="pointer"
                                  onClick={() => {
                                    setAddData({
                                      id: items?.id,
                                      property_margin: items?.property_margin,
                                      buffer_margin: items?.buffer_margin,
                                    });
                                    handleSwitch();
                                  }}
                                >
                                  <SvgEdit />
                                </span>
                                <span
                                  className="pointer"
                                  onClick={() => deleteSettings(items?.id)}
                                >
                                  <SvgDelete />
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="flex justify_content_center mt-10">
                      <PrimaryButton
                        size={"medium"}
                        onClick={handleSwitch}
                        title={"Add"}
                        style={{ margin: "0" }}
                      />
                    </div>

                    <div
                      className={`${styles.settingsAdd} ${
                        switchForm ? styles.addShow : styles.addHide
                      }`}
                    >
                      <div className="flex flex_gap_10">
                        <div>
                          <PrimaryInput
                            onChange={handleChange}
                            type={"number"}
                            name={"buffer_margin"}
                            value={addData.buffer_margin}
                            size={"medium"}
                            label={"Buffer"}
                          />
                        </div>
                        <div>
                          <PrimaryInput
                            onChange={handleChange}
                            type={"number"}
                            name={"property_margin"}
                            value={addData.property_margin}
                            size={"medium"}
                            label={"Property Margin"}
                          />
                        </div>
                      </div>
                      <div className="flex flex_gap_10 align_items_end">
                        <PrimaryButton
                          onClick={(e) => AddSettings(e)}
                          size={"medium"}
                          title={"Add"}
                        />
                        <SecondaryButton
                          size={"medium"}
                          title={"Cancel"}
                          onClick={() => {
                            handleSwitch();
                            setAddData({
                              property_margin: "",
                              buffer_margin: "",
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* Buffer End */}

                {/* Occupancy */}
                {switchTabs === "occupancy" && (
                  <>
                  <div className={`${styles.settingsOccupancy}`}>
                    <div className="flex flex_gap_20 mb-15">
                      <div>
                        <label className="flex align_items_center flex_gap_10">
                          <input
                            type="radio"
                            name="Hilly Areas"
                            value="Hilly Areas"
                            checked={switchArea === "Hilly Areas"}
                            onChange={handleSwitchArea}
                          />
                          Hilly Area
                        </label>
                      </div>
                      <div>
                        <label className="flex align_items_center flex_gap_10">
                          <input
                            type="radio"
                            name="Plain Areas"
                            value="Plain Areas"
                            checked={switchArea === "Plain Areas"}
                            onChange={handleSwitchArea}
                          />
                          Plain Area
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className={styles.recommendedWrapper}>
                        {recommended.occupancies &&
                          recommended.occupancies.length > 0 &&
                          recommended.occupancies
                            // .slice(0, 6)
                            .map((items, index) => (
                              <div
                                key={index}
                                className={`${styles.recommendedMain} flex align_items_center`}
                              >
                                <div className={styles.recommendedMonth}>
                                  {items.month}
                                </div>
                                <div style={{ width: "60px" }}>
                                  <VerticalInputFieldCustom
                                    name={"occupancy_percent"}
                                    value={items.occupancy_percent}
                                    onChange={(e) =>
                                      handelOccupancyChange(e, items.month)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                      </div>
                      {/* <div className={styles.recommendedWrapper}>
                        {recommended.occupancies &&
                          recommended.occupancies.length > 0 &&
                          recommended.occupancies
                            .slice(6, 12)
                            .map((items, secondIndex) => (
                              <div
                                key={secondIndex}
                                className={`${styles.recommendedMain} flex align_items_center`}
                              >
                                <div className={styles.recommendedMonth}>
                                  {items.month}
                                </div>
                                <div style={{ width: "60px" }}>
                                  <VerticalInputFieldCustom
                                    name={"occupancy_percent"}
                                    value={items.occupancy_percent}
                                    onChange={(e) =>
                                      handelOccupancyChange(e, items.month)
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                      </div> */}
                    </div>

                   
                  </div>
                  
                  <div className="flex justify_content_center">
                  <PrimaryButton
                      onClick={handleUpdateRecommended}
                      size={"medium"}
                      title={"Update"}
                    />
                  </div>
                  </>
                )}
                {/* Occupancy End */}
              </div>

              {/* <div
                className={`${styles.settingsAdd} ${
                  switchOccupancy ? styles.addShow : styles.addHide
                }`}
              >
                <div className="flex align_items_center flex_gap_10 mb-10">
                  <span
                    className="pointer"
                    onClick={() => {
                      handleSwitchOccupancy();
                      setAddData({ property_margin: "", buffer_margin: "" });
                    }}
                  >
                    <SvgBack />
                  </span>
                  <h4>Update Occupancy</h4>
                </div>
                <div className="flex flex_gap_10 mb-15 flex_dir_col">
                  <div>
                    <label className="flex align_items_center flex_gap_10">
                      <input
                        type="radio"
                        name="Hilly Areas"
                        value="Hilly Areas"
                        checked={switchArea === "Hilly Areas"}
                        onChange={handleSwitchArea}
                      />
                      Hilly Area
                    </label>
                  </div>
                  <div>
                    <label className="flex align_items_center flex_gap_10">
                      <input
                        type="radio"
                        name="Plain Areas"
                        value="Plain Areas"
                        checked={switchArea === "Plain Areas"}
                        onChange={handleSwitchArea}
                      />
                      Plain Area
                    </label>
                  </div>
                </div>
                <div className="flex justify_content_between">
                  <div className={styles.recommendedWrapper}>
                    {recommended.occupancies &&
                      recommended.occupancies.length > 0 &&
                      recommended.occupancies
                        .slice(0, 6)
                        .map((items, index) => (
                          <div
                            key={index}
                            className={`${styles.recommendedMain} flex align_items_center`}
                          >
                            <div className={styles.recommendedMonth}>
                              {items.month}
                            </div>
                            <div style={{ width: "60px" }}>
                              <VerticalInputFieldCustom
                                name={"occupancy_percent"}
                                value={items.occupancy_percent}
                                onChange={(e) =>
                                  handelOccupancyChange(e, items.month)
                                }
                              />
                            </div>
                          </div>
                        ))}
                  </div>
                  <div className={styles.recommendedWrapper}>
                    {recommended.occupancies &&
                      recommended.occupancies.length > 0 &&
                      recommended.occupancies
                        .slice(6, 12)
                        .map((items, secondIndex) => (
                          <div
                            key={secondIndex}
                            className={`${styles.recommendedMain} flex align_items_center`}
                          >
                            <div className={styles.recommendedMonth}>
                              {items.month}
                            </div>
                            <div style={{ width: "60px" }}>
                              <VerticalInputFieldCustom
                                name={"occupancy_percent"}
                                value={items.occupancy_percent}
                                onChange={(e) =>
                                  handelOccupancyChange(e, items.month)
                                }
                              />
                            </div>
                          </div>
                        ))}
                  </div>
                </div>

                <PrimaryButton
                  onClick={handleUpdateRecommended}
                  size={"medium"}
                  title={"Update"}
                />
              </div> */}
            </div>
          </div>
        )}
      </div>

      <div style={{ background: "#fff", padding: "20px" }}>
        {listTargets && listTargets.property_targets_list.data.length > 0 && (
          <>
            <CustomShopifyIndexTable
              headings={["Date", "Trg. ADR", "Trg. Occ", "Trg. RevPar"]}
              style={{ border: "1px solid #ccc" }}
            >
              {listTargets?.property_targets_list?.data?.length > 0 &&
                listTargets?.property_targets_list?.data?.map(
                  (items, index) => {
                    return (
                      <>
                        <CustomShopifyIndexTableRow>
                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !==
                                  listTargets?.property_targets_list?.data
                                    ?.length -
                                    1 && "1px solid #ccc",
                            }}
                          >
                            {getDateByMonth(items?.date)}
                          </CustomShopifyIndexTableCell>

                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !==
                                  listTargets?.property_targets_list?.data
                                    ?.length -
                                    1 && "1px solid #ccc",
                            }}
                          >
                            {items?.target_ar}
                          </CustomShopifyIndexTableCell>

                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !==
                                  listTargets?.property_targets_list?.data
                                    ?.length -
                                    1 && "1px solid #ccc",
                            }}
                          >
                            {items?.target_occupancy}
                          </CustomShopifyIndexTableCell>

                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !==
                                  listTargets?.property_targets_list?.data
                                    ?.length -
                                    1 && "1px solid #ccc",
                            }}
                          >
                            {items?.revpar}
                          </CustomShopifyIndexTableCell>
                        </CustomShopifyIndexTableRow>
                      </>
                    );
                  }
                )}
            </CustomShopifyIndexTable>

            <div className="flex justify_content_center align_items_center mt-20">
              <Pagination
                type={"advance"}
                totalPages={Number(listTargets.property_targets_list.last_page)}
                currentPage={listTargets.property_targets_list.current_page}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </div>

      {addTarget && (
        <div className="bg-backdrop">
          <div
            className={`${styles.popAddTargets} ${
              addTarget ? styles.show : styles.hide
            }`}
          >
            <AddTargetPageNew
              closePopup={handleAddTarget}
              refreshListApi={fetchListTargets}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingPropertyTargets;

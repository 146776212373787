  import React from "react";
import logo from "../../assets/images/logo.png";
import user from "../../assets/images/user.png";
import SearchInput from "../CustomComponents/SearchInput";

import { SearchMajor, NotificationFilledMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import SearchComponent from "../SearchField";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { BgThemeButton } from "../UI/Buttons";
import AdditionalLinks from "./AdditionalLinks";
import { getDecryptedData } from "../../assets/encryptStorage";

const Navbar = () => {
  const navigate = useNavigate();
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");
  const logoutHandle = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/logout`, {
      method: "POST",
      headers: { Authorization: `Bearer ${userToken} ` },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          navigate("/login");
          localStorage.clear();
        } else {
          showErrorToast("Something Went Wrong! Please Try again later");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  return (
    <header className="navbar-container" > 
      <div className="nav-header" >
        <div className="nav-logo">
          <Link to={"/dashboard"}>
            <img className="nav-logo--image" src={logo} alt="logo" />
          </Link>
        </div>
        <div style={{width:"40%"}}>
        <AdditionalLinks/>
        </div>
        <div className="flex flex_gap_10">
          <button className="icon_button_only" onClick={logoutHandle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
            >
              <path
                d="M23.3334 14.5001H12.2501M21.0001 18.0001L24.5001 14.5001L21.0001 11.0001M15.1667 8.66675V7.50008C15.1667 6.88124 14.9209 6.28775 14.4833 5.85017C14.0457 5.41258 13.4523 5.16675 12.8334 5.16675H7.00008C6.38124 5.16675 5.78775 5.41258 5.35017 5.85017C4.91258 6.28775 4.66675 6.88124 4.66675 7.50008V21.5001C4.66675 22.1189 4.91258 22.7124 5.35017 23.15C5.78775 23.5876 6.38124 23.8334 7.00008 23.8334H12.8334C13.4523 23.8334 14.0457 23.5876 14.4833 23.15C14.9209 22.7124 15.1667 22.1189 15.1667 21.5001V20.3334"
                stroke="#AAAAAA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>

          {/* <button className="icon_button_only">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
            >
              <path
                d="M20.9997 8.66667H6.99967V4H20.9997V8.66667ZM20.9997 15.0833C21.3302 15.0833 21.6075 14.9713 21.8315 14.7473C22.0555 14.5233 22.1671 14.2464 22.1663 13.9167C22.1663 13.5861 22.0543 13.3088 21.8303 13.0848C21.6063 12.8608 21.3295 12.7492 20.9997 12.75C20.6691 12.75 20.3918 12.862 20.1678 13.086C19.9438 13.31 19.8322 13.5869 19.833 13.9167C19.833 14.2472 19.945 14.5245 20.169 14.7485C20.393 14.9725 20.6699 15.0841 20.9997 15.0833ZM18.6663 22.6667V18H9.33301V22.6667H18.6663ZM20.9997 25H6.99967V20.3333H2.33301V13.3333C2.33301 12.3417 2.67329 11.5102 3.35384 10.839C4.0344 10.1678 4.86079 9.83256 5.83301 9.83333H22.1663C23.158 9.83333 23.9895 10.1689 24.6607 10.8402C25.3319 11.5114 25.6671 12.3424 25.6663 13.3333V20.3333H20.9997V25Z"
                fill="#3968ED"
              />
            </svg>
          </button> */}

          {/* <Link
            to={"/bookings/createbooking"}
            style={{ textDecoration: "none" }}
          > */}
          <BgThemeButton
            style={{ cursor: "no-drop", opacity: ".8" }}
            children={"Account Settings"}
          />
          {/* </Link> */}

          {/* <div className="nav-user">
            <Icon
              className="nav-user-bell"
              source={NotificationFilledMajor}
              color="base"
            />
            <img src={user} alt="user image" />
            <div className="nav-user-info"><Link to='/profile'>Hi, John</Link></div>
          </div> */}
        </div>
      </div>
      
    </header>
  );
};

export default Navbar;

import { Route, Routes } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import Home from "./Home";
import Profile from "./profile";
import Dashboard from "./Dashboard";
import RoomsPage from "./RoomsPage";
import RoomsTypePage from "./RoomsTypePage";
import RoomTypeAddPage from "./RoomTypeAddPage";
import RoomsPlanPage from "./RoomsPlanPage";
import RoomsRatePlanPage from "./RoomsRatePlanPage";
import CompetitorsPage from "./CompetitorsPage";
import CompetitorsManagePage from "./CompetitorsManagePage";
import RoomTypeEditPage from "./RoomTypeEditPage";
import AgentPage from "./AgentPage";
import AgentEditProfilePage from "./AgentEditProfilePage";
import AgentAddProfilePage from "./AgentAddProfilePage";
import SegmentPage from "./SegmentPage";
import DiscountsPage from "./DiscountsPage";
import CreateSegmentPage from "./CreateSegmentPage";
import UpgradePage from "./UpgradePage";
import UpgradePlanPage from "./UpgradePlanPage";
import AddDiscountPage from "./AddDiscountPage";
import BookingPage from "./BookingPage";
import OtasPage from "./OtasPage";
import SuppliersPage from "./SuppliersPage";
import ItemPage from "./ItemPage";
import PurchasePage from "./PurchasePage";
import NewSidebar from "../Components/NewSidebar";
import InventoryPage from "./InventoryPage";
import CompetitiveAnalysisPage from "./CompetitiveAnalysisPage";
import CustomersPage from "./CustomersPage";
import LowInventoryRatesPage from "./LowInventoryRatesPage";
import CreateBooking from "../Components/CreateBooking";
import CreateBookingPage from "./CreateBookingPage";
import AddPropertyPage from "./AddPropertyPage";
import PropertyPage from "./PropertyPage";
import Sidebar from "../Components/Sidebar";
import PreviewBillPage from "./PreviewBillPage";
import SettingsPage from "./SettingsPage";
import NoPropertyPage from "./NoPropertyPage";
import Login from "../Components/Auth/Login";
import ProtectedRoute from "../Components/Auth/ProtectedRoutes";
import { useState } from "react";
import ProtectedRoutes from "../Components/Auth/ProtectedRoutes";
import DashboardPage from "./Dashboard";
import CustomerAddProfilePage from "./CustomerAddProfilePage";
import SingleCustomerPage from "./SingleCustomerPage";
import AddCustomerProperty from "../Components/AddCustomerProperty";
import AddCustomerPropertyPage from "./AddCustomerPropertyPage";
import PlanPage from "./PlanPage";
import ChannelsPage from "./ChannelsPage";
import RateCalender from "./RateCalenderPage";
import RateCalenderPage from "./RateCalenderPage";
import PropertyRateCalenderPage from "./PropertyRateCalenderPage";
import TargetPage from "./TargetPage";
import AddTargetPage from "./AddTargetPage";
import RolesPermissions from "../Components/RolesPermissions";
import RolesAndPermissionPage from "./RolesAndPermissionPage";
import Channels from "../Components/Channels";
import PlanFeatures from "../Components/PlanFeatures";
import EditPermissions from "./EditPermissions";
import AddonsPage from "./AddonsPage";
import AllAmenitiesPage from "./AllAmenitiesPage";
import SalutationPage from "./SalutationPage";
import ChannelDetailsPage from "./ChannelDetailsPage";
import AddTargetPageNew from "./AddTargetPageNew";
import ListingPropertyTargets from "../Components/CustomComponents/ListingPropertyTargets/ListingPropertyTargets";
import PropertyTargets from "../Components/CustomComponents/PropertyTargets/PropertyTargets";
import CompetitorsTargets from "../Components/CustomComponents/PropertyTargets/CompetitorsTargets";
import PropertyTargetsPage from "../Components/CustomComponents/PropertyTargets/PropertyTargetsPage";
import ChannelManagerSettingsPage from "./ChannelManagerSettingsPage";
import PaymentGateway from "./settings/PaymentGateway";
import Settings from "./settings/Settings";

const Layout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      <Navbar />
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/nopropertypage" element={<NoPropertyPage />} />
          <Route path="/plan-module" element={<PlanFeatures />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/rooms"} element={<RoomsPage />} />
          <Route path="/roomstype" element={<RoomsTypePage />} />
          <Route path="/roomstype/edit" element={<RoomTypeEditPage />} />
          <Route path="/roomstype/add" element={<RoomTypeAddPage />} />
          <Route path={"/roomsplans"} element={<RoomsPlanPage />} />
          <Route path={"/roomsrateplans"} element={<RoomsRatePlanPage />} />
          <Route path={"/competitors"} element={<CompetitorsPage />} />
          <Route
            path={"/competitors/manage"}
            element={<CompetitorsManagePage />}
          />

          <Route path={"/roles"} element={<RolesAndPermissionPage />} />
          <Route
            path={"/edit/module/:moduleId"}
            element={<EditPermissions />}
          />
          <Route
            path={"/plans/edit/:id"}
            element={<RolesAndPermissionPage />}
          />

          <Route path={"/agents"} element={<AgentPage />} />
          <Route path={"/customers"} element={<CustomersPage />} />
          <Route
            path={"/customers/addprofile"}
            element={<CustomerAddProfilePage />}
          />
          <Route
            path={"/agent/editprofile"}
            element={<AgentEditProfilePage />}
          />
          <Route path={"/agent/addprofile"} element={<AgentAddProfilePage />} />
          <Route path={"/segments"} element={<SegmentPage />} />
          <Route
            path={"/segments/createsegment"}
            element={<CreateSegmentPage />}
          />
          <Route path={"/discounts"} element={<DiscountsPage />} />
          <Route
            path={"/discounts/adddiscounts"}
            element={<AddDiscountPage />}
          />
          <Route path={"/upgrade"} element={<UpgradePage />} />
          <Route path={"/upgrade/plans"} element={<UpgradePlanPage />} />
          <Route path={"/inventory"} element={<InventoryPage />} />
          <Route path={"/lowinventory"} element={<LowInventoryRatesPage />} />

          <Route path={"/bookings"} element={<BookingPage />} />
          <Route
            path={"/bookings/createbooking"}
            element={<CreateBookingPage />}
          />
          <Route path={"/bookings/previewbill"} element={<PreviewBillPage />} />

          <Route path="/property" element={<PropertyPage />} />

          <Route path={"/property/addproperty"} element={<AddPropertyPage />} />
          <Route
            path={"/ratescalender/:compId"}
            element={<RateCalender />}
          ></Route>
          <Route
            path={"/propertyrates/:compId"}
            element={<PropertyRateCalenderPage />}
          ></Route>
          <Route path={"/hotelier/otas"} element={<OtasPage />} />
          <Route path={"/hotelier/suppliers"} element={<SuppliersPage />} />
          <Route path={"/hotelier/items"} element={<ItemPage />} />

          <Route path={"/hotelier/purchase"} element={<PurchasePage />} />

          <Route
            path={"competitiveanalysis"}
            element={<CompetitiveAnalysisPage />}
          />
          {/* <Route path={"/settings"} element={<SettingsPage />} /> */}
          <Route
            path={"/channel-manager-settings"}
            element={<ChannelManagerSettingsPage />}
          />

          {/* Admin */}

          <Route path={"/customers"} element={<CustomersPage />} />
          <Route
            path={"/singlecustomer/:customerId"}
            element={<SingleCustomerPage />}
          />
          <Route
            path={"/customers/addproperty/:customerId"}
            element={<AddCustomerPropertyPage />}
          />
        </Route>
        <Route path={"/plans"} element={<PlanPage />} />
        <Route path={"/properties"} element={<PropertyPage />} />
        <Route path={"/addons"} element={<AddonsPage />} />
        <Route path={"/amenities"} element={<AllAmenitiesPage />} />
        <Route path={"/salutation"} element={<SalutationPage />} />

        <Route path={"/channels"} element={<ChannelsPage />} />
        <Route path={"/channels/:ID"} element={<ChannelDetailsPage />} />

        <Route
          path={"/targets/:propertyId"}
          element={<ListingPropertyTargets />}
        />

        <Route path={"/banquetmenus"} element={<ChannelsPage />} />

        <Route path="/" element={<Login />} />

        {/* Settings Routes */}
        <Route path={"/settings"} element={<Settings />}>
          <Route path={"payment-gateway"} element={<PaymentGateway />} />
        </Route>
        {/* Settings Routes End */}
      </Routes>

      {/* <Sidebar /> */}
    </>
  );
};

export default Layout;

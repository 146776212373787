import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorToast } from "../assets/toastUtils";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import Loader from "./UI/Loader/Loader";
import InfiniteLoading from "../Components/CustomComponents/InfiniteLoading/InfiniteLoading";
import { SvgDelete } from "./svgIcons/Icons";
import ConfirmPopup from "./CustomComponents/NewCustomComp/confirmPopup/ConfirmPopup";
import NoDataFound from "./CustomComponents/NoDataFound copy";
import Pagination from "./CustomComponents/NewCustomComp/pagination/Pagination";
import { getDecryptedData } from "../assets/encryptStorage";
import GoBackButtonCustom from "./CustomComponents/GoBackButtonCustom/GoBackButtonCustom";

const ChannelDetails = () => {
  const { ID } = useParams();
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  const userToken = getDecryptedData("encryptToken");

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [loader, setLoader] = useState(false);

  const fetchDetails = async (nextPage) => {
    setLoader(true);
    const url = nextPage
      ? `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/channel/mappings/${ID}?page=${nextPage}`
      : `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/channel/mappings/${ID}`;
    try {
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (res.ok) {
        const json = await res.json();
        setData(json.data.data);
        setLoader(false);
        setPageInfo({
          current_page: json.data.current_page,
          last_page: json.data.last_page,
        });
      }
      // json.data.nextPageUrl
    } catch (error) {
      showErrorToast(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (ID && userToken) {
      fetchDetails();
    }
  }, [ID, userToken]);

  const [deleteId, setDeleteId] = useState(null);

  const handleDelete = async (id) => {
    setDeleteId(id);
  };

  const apiCallToDelete = async () => {
    // v1/admin/remove/channel/mappings
    try {
      const response = await fetch(
        // {{live}}/api/v1/admin/plans/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/remove/channel/mappings`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "DELETE",
          body: JSON.stringify({
            id: deleteId,
          }),
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      fetchDetails();
      setDeleteId(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (page) => {
    fetchDetails(page);
  };
  return (
    <div className="room-types-full-container">
      <div style={{ background: "#fff", padding: "20px" }}>
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex_gap_10 align_items_center mb-20">
              <GoBackButtonCustom onClick={() => navigate(-1)} />

              <span style={{ fontWeight: "600", fontSize: "20px" }}>
                {" "}
                Channel Details
              </span>
            </div>

            {/* <InfiniteLoading fetchInfo={fetchDetails} nextPageUrl={nextPageUrl}> */}
            {data && data.length > 0 ? (
              <>
                <CustomShopifyIndexTable
                  headings={[
                    "Sr.No",
                    "Property Name",
                    "Channel ID",
                    "Room Type",
                    "Meal Plan",
                    "",
                  ]}
                  style={{ border: "1px solid #ccc" }}
                >
                  {data?.map((item, index) => {
                    return (
                      <>
                        <CustomShopifyIndexTableRow>
                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !== data.length - 1 && "1px solid #ccc",
                            }}
                          >
                            {index + 1}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !== data.length - 1 && "1px solid #ccc",
                            }}
                          >
                            {item.property_name || "N/A"}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !== data.length - 1 && "1px solid #ccc",
                            }}
                          >
                            {item.channel_unique_id || "N/A"}
                          </CustomShopifyIndexTableCell>

                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !== data.length - 1 && "1px solid #ccc",
                            }}
                          >
                            {item.room_name || "N/A"}
                          </CustomShopifyIndexTableCell>
                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !== data.length - 1 && "1px solid #ccc",
                            }}
                          >
                            {item.rate_plan_name || "N/A"}
                          </CustomShopifyIndexTableCell>

                          <CustomShopifyIndexTableCell
                            style={{
                              borderBottom:
                                index !== data.length - 1 && "1px solid #ccc",
                            }}
                          >
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(item.id)}
                            >
                              <SvgDelete />
                            </span>
                          </CustomShopifyIndexTableCell>
                        </CustomShopifyIndexTableRow>
                      </>
                    );
                  })}
                </CustomShopifyIndexTable>

                <div style={{ width: "max-content", margin: "20px auto" }}>
                  <Pagination
                    type={"advance"}
                    totalPages={pageInfo.last_page}
                    currentPage={pageInfo.current_page}
                    onPageChange={handlePageChange}
                  />
                </div>
              </>
            ) : (
              <NoDataFound />
            )}
            {/* </InfiniteLoading> */}
          </>
        )}
      </div>

      {deleteId && (
        <ConfirmPopup
          heading={"Are you sure ?"}
          subHeading={"Please confirm your action."}
          setShowPopup={setDeleteId}
          handleConfirmClick={apiCallToDelete}
        />
      )}
    </div>
  );
};

export default ChannelDetails;

import React  from "react";
import { PrimaryInput } from "../CustomComponents/NewCustomComp/Inputs/Inputs";
import { SvgDelete, SvgPlus } from "../svgIcons/Icons";
import { showErrorToast } from "../../assets/toastUtils";

const RoomsSetup = ({roomData, setRoomData,rooms, setRooms}) => {


  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoomData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddRoom = () => {
    const {
      categoryName,
      totalRooms,
      rackPrice,
      extraAdults,
      childWithBed,
      childWithoutBed,
    } = roomData;

    if (
      !categoryName ||
      !totalRooms ||
      !rackPrice ||
      !extraAdults ||
      !childWithBed ||
      !childWithoutBed
    ) {
      showErrorToast("Please fill in all fields.");
      return;
    }

    const isDuplicate = rooms.some(
      (room) => room.categoryName === categoryName
    );
    if (isDuplicate) {
      showErrorToast("Room category already exists.");
      return;
    }

    const newRoom = {
      categoryName,
      totalRooms: Number(totalRooms),
      rackPrice: Number(rackPrice),
      extraAdults: Number(extraAdults),
      childWithBed: Number(childWithBed),
      childWithoutBed: Number(childWithoutBed),
    };

    setRooms([...rooms, newRoom]);
    setRoomData({
      categoryName: "",
      totalRooms: "",
      rackPrice: "",
      extraAdults: "",
      childWithBed: "",
      childWithoutBed: "",
    });
  };

  const handleDeleteRoom = (index) => {
    setRooms(rooms.filter((_, i) => i !== index));
  };

  return (
    <div>
      <div className="flex flex_gap_10 align_items_end mb-10">
        <PrimaryInput
          label={"Category Name"}
          size={"medium"}
          type={"text"}
          name="categoryName"
          value={roomData.categoryName}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Total Rooms"}
          size={"medium"}
          type={"number"}
          name="totalRooms"
          value={roomData.totalRooms}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Rack Price"}
          size={"medium"}
          type={"number"}
          name="rackPrice"
          value={roomData.rackPrice}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Extra Adults"}
          size={"medium"}
          type={"number"}
          name="extraAdults"
          value={roomData.extraAdults}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Child w Bed"}
          size={"medium"}
          type={"number"}
          name="childWithBed"
          value={roomData.childWithBed}
          onChange={handleChange}
        />
        <PrimaryInput
          label={"Child w/o Bed"}
          size={"medium"}
          type={"number"}
          name="childWithoutBed"
          value={roomData.childWithoutBed}
          onChange={handleChange}
        />
        <span className="pointer" onClick={handleAddRoom}>
          <SvgPlus />
        </span>
      </div>

      <div className="rooms-list mb-10">
        {rooms.length > 0 &&
          rooms.map((room, index) => (
            <div key={index} className="flex flex_gap_10 align_items_end mt-10">
              <PrimaryInput
                size={"medium"}
                type={"text"}
                name="categoryName"
                value={room.categoryName}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                type={"number"}
                name="totalRooms"
                value={room.totalRooms}
                onChange={handleChange}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                type={"number"}
                name="rackPrice"
                value={room.rackPrice}
                onChange={handleChange}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                type={"number"}
                name="extraAdults"
                value={room.extraAdults}
                onChange={handleChange}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                type={"number"}
                name="childWithBed"
                value={room.childWithBed}
                onChange={handleChange}
                disabled={true}
              />
              <PrimaryInput
                size={"medium"}
                type={"number"}
                name="childWithoutBed"
                value={room.childWithoutBed}
                onChange={handleChange}
                disabled={true}
              />

              <span onClick={() => handleDeleteRoom(index)} className="pointer">
                <SvgDelete />
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RoomsSetup;
